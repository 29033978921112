import { Paper, styled } from '@mui/material'

import { PickersLayout } from '@mui/x-date-pickers'

export const StyledPaper = styled(Paper)`
  background-color: var(--Neutral8);
  border-radius: 4px;
`

export const StyledPickersLayout = styled(PickersLayout)`
  .MuiDateCalendar-root {
    background-color: var(--Neutral8);
    color: var(--Neutral4);
  }

  .MuiPickersToolbar-root {
    background-color: var(--Neutral8);
    color: var(--Neutral4);

    > .MuiTypography-root {
      color: var(--Neutral4);
    }
  }

  .MuiPickersLayout-actionBar {
    background-color: var(--Neutral8);
    color: var(--Neutral4);

    button {
      color: var(--Neutral4);

      &:hover,
      &:active {
        background-color: var(--Neutral6);
      }
    }
  }

  .MuiDayCalendar-weekContainer {
    .MuiPickersDay-root {
      color: var(--Neutral4);

      &.Mui-selected {
        background-color: var(--Secondary);
        color: var(--Neutral1);
      }

      &.Mui-disabled {
        color: var(--Neutral6);
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:active {
          background-color: var(--Neutral6);
        }
      }
    }
  }
  .MuiDateCalendar-root {
    border-radius: 4px;
  }

  .MuiPickersMonth-root {
    color: var(--Neutral4);
  }

  .MuiDayCalendar-weekDayLabel {
    color: var(--Neutral4);
  }

  .MuiPickersCalendarHeader-switchViewIcon {
    color: var(--Neutral1);
  }

  .MuiPickersArrowSwitcher-button {
    color: var(--Neutral1);

    &.Mui-disabled {
      color: var(--Neutral6);
    }
  }
`

import { Pagination, styled } from '@mui/material'

export const StyledPagination = styled(Pagination)`
  li {
    div {
      font-family: 'Poppins', sans-serif;
      color: var(--Neutral4);
    }

    button {
      font-family: 'Poppins', sans-serif;
      border-radius: 8px;
      color: var(--Neutral4);

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--Neutral7);
        }
      }
    }

    .Mui-disabled {
      color: var(--Neutral5);
      background-color: transparent;
    }

    .Mui-selected {
      color: var(--Neutral1) !important;
      background-color: var(--Primary) !important;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--Primary) !important;
        }
      }
    }
  }
`

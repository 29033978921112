import { SvgIcon, type SvgIconProps } from '@mui/material'

const GooglePayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3518 11.3516V7.44951H17.3657C18.191 7.44951 18.8875 7.17299 19.4554 6.62762L19.5916 6.48936C20.6289 5.36022 20.5607 3.60121 19.4554 2.55657C18.9027 2.00352 18.1455 1.70395 17.3657 1.71931H14.1328V11.3516H15.3518ZM15.3521 6.26652V2.90214H17.3966C17.8358 2.90214 18.2523 3.07113 18.5627 3.37838C19.2215 4.0236 19.2367 5.09897 18.6006 5.76724C18.2901 6.09753 17.8509 6.28188 17.3966 6.26652H15.3521ZM25.2777 5.2756C24.7553 4.79168 24.0436 4.54588 23.1426 4.54588C21.9842 4.54588 21.1135 4.97603 20.5381 5.82864L21.6132 6.51227C22.0069 5.9285 22.5445 5.63661 23.2259 5.63661C23.6574 5.63661 24.0739 5.79792 24.3994 6.08981C24.7174 6.36633 24.8991 6.76575 24.8991 7.18822V7.47243C24.4297 7.21127 23.8391 7.073 23.1123 7.073C22.2643 7.073 21.5829 7.27272 21.0757 7.67982C20.5684 8.08693 20.311 8.62461 20.311 9.30824C20.2958 9.93042 20.5608 10.5219 21.0302 10.9213C21.5072 11.3515 22.1129 11.5665 22.8246 11.5665C23.665 11.5665 24.3313 11.1901 24.8385 10.4374H24.8915V11.3515H26.0575V7.28808C26.0575 6.43546 25.8001 5.75951 25.2777 5.2756ZM21.9694 10.1368C21.7195 9.95249 21.5681 9.65292 21.5681 9.33031C21.5681 8.96929 21.7347 8.66972 22.0603 8.4316C22.3935 8.19349 22.8099 8.07059 23.3021 8.07059C23.9837 8.06291 24.5137 8.21653 24.8923 8.52378C24.8923 9.0461 24.6879 9.4993 24.2865 9.88336C23.9231 10.2521 23.4309 10.4594 22.9159 10.4594C22.5752 10.4671 22.242 10.3519 21.9694 10.1368ZM28.6771 14.2467L32.7504 4.76034H31.4254L29.5402 9.49197H29.5175L27.5868 4.76034H26.2619L28.9345 10.9284L27.4203 14.2467H28.6771Z"
        fill="#3C4043"
      />

      <path
        d="M11.1428 6.60584C11.1428 6.22945 11.1125 5.85307 11.052 5.48438H5.91113V7.61208H8.85632C8.73518 8.29571 8.34148 8.9102 7.76607 9.29427V10.6769H9.52258C10.5523 9.71673 11.1428 8.29571 11.1428 6.60584Z"
        fill="#4285F4"
      />

      <path
        d="M5.91124 12.0144C7.38005 12.0144 8.62172 11.5228 9.52269 10.6779L7.76618 9.29527C7.27405 9.63324 6.64564 9.82527 5.91124 9.82527C4.48786 9.82527 3.28404 8.84976 2.85248 7.54395H1.04297V8.97265C1.96665 10.8392 3.85188 12.0144 5.91124 12.0144Z"
        fill="#34A853"
      />

      <path
        d="M2.85278 7.54203C2.6256 6.8584 2.6256 6.11332 2.85278 5.422V4.00098H1.04297C0.263019 5.56027 0.263019 7.40376 1.04297 8.96305L2.85278 7.54203Z"
        fill="#FBBC04"
      />

      <path
        d="M5.91124 3.14163C6.69107 3.12627 7.44062 3.42584 8.00088 3.97121L9.56054 2.38887C8.56872 1.45176 7.26648 0.93712 5.91124 0.952483C3.85188 0.952483 1.96665 2.13539 1.04297 4.00193L2.85248 5.43064C3.28404 4.11715 4.48786 3.14163 5.91124 3.14163Z"
        fill="#EA4335"
      />
    </SvgIcon>
  )
}

export default GooglePayIcon

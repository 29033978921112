import { useCallback, useEffect, useState } from 'react'

import { DialogHeader, Dialog, Typography, Button } from '@lib/ui'

import { isBrowser } from '@helpers/isBrowser'

import { Box, Stack } from '@mui/material'

import { StyledWifiSlashIcon } from './OfflineNotification.styled'

const OfflineNotification = () => {
  const [isOffline, setIsOffline] = useState(false)

  const handleOnline = useCallback(() => {
    setIsOffline(false)
  }, [])

  const handleOffline = useCallback(() => {
    setIsOffline(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOffline(false)
  }, [])

  useEffect(() => {
    if (isBrowser()) {
      setIsOffline(!navigator.onLine)
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  if (!isOffline) {
    return null
  }

  return (
    <Dialog isOpen={isOffline} mode="dark" onClose={handleClose}>
      <DialogHeader
        dialogTitle="Connection lost"
        onClose={handleClose}
        mode="dark"
        paddingBottom="24px"
      />

      <Stack direction="column" alignItems="center" gap="16px">
        <StyledWifiSlashIcon viewBox="0.5 0.8 48 38.4" />

        <Typography
          fontVariant="heading-3"
          weight="semibold"
          color="var(--Neutral1)"
          textAlign="center"
          paddingTop="12px"
        >
          No Internet Connection
        </Typography>

        <Typography
          fontVariant="body-1"
          weight="regular"
          color="var(--Neutral1)"
          textAlign="center"
        >
          It looks like you’re offline. Please check your connection and try
          again to continue enjoying our features.
        </Typography>

        <Box
          width={{
            lg: 'fit-content',
            md: 'fit-content',
            sm: '100%',
            xs: '100%'
          }}
        >
          <Button
            type="button"
            variant="primary"
            size="large"
            width="100%"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}

export default OfflineNotification

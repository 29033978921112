import React from 'react'

import { observable, action, configure, reaction } from 'mobx'

import { Typography } from '@lib/ui'

import { Stack } from '@mui/material'

type NotificationType = 'success' | 'error' | 'info' | 'warn'

const Wrapper = ({
  message,
  type
}: {
  message: string
  type: NotificationType
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      gap="8px"
    >
      {type === 'success' && (
        <Typography
          fontVariant="body-2"
          weight="regular"
          color="var(--SecondaryVariant)"
        >
          <i className="fa-regular fa-check" />
        </Typography>
      )}

      {type === 'error' && (
        <Typography
          fontVariant="body-2"
          weight="regular"
          color="var(--Primary)"
        >
          <i className="fa-regular fa-xmark" />
        </Typography>
      )}

      {type === 'warn' && (
        <Typography
          fontVariant="body-2"
          weight="regular"
          color="var(--SecondaryVariant)"
        >
          <i className="fa-regular fa-triangle-exclamation" />
        </Typography>
      )}

      {type === 'info' && (
        <Typography
          fontVariant="body-2"
          weight="regular"
          color="var(--Secondary)"
        >
          <i className="fa-regular fa-circle-info" />
        </Typography>
      )}

      <Typography fontVariant="body-2" weight="regular">
        {message}
      </Typography>
    </Stack>
  )
}

configure({
  enforceActions: 'observed'
})

export class NotificationStore {
  @observable toastId = null

  @observable message = ''

  @observable type: NotificationType = 'success'

  @observable notificationCount = 0

  constructor() {
    reaction(
      () => {
        if (this.message !== '') {
          return {
            type: this.type,
            message: this.message
          }
        }
      },
      toastConfig => {
        if (toastConfig) {
          this.notifyReaction(toastConfig)
        }
      }
    )

    if (document.readyState === 'complete') {
      this.loadToastify()
    } else {
      window.addEventListener('load', this.loadToastify.bind(this))
    }
  }

  toast = null

  async loadToastify() {
    this.toast = (await import('react-toastify')).toast
  }

  notifyReaction({
    type,
    message
  }: {
    message: string
    type: NotificationType
  }) {
    if (this.toast && !this.toast.isActive(this.toastId)) {
      const fn: NotificationType = [
        'success',
        'error',
        'info',
        'warn'
      ].includes(type)
        ? type
        : 'success'

      this.toastId = this.toast[fn](<Wrapper message={message} type={type} />, {
        onClose: action(() => {
          this.message = ''
          this.type = 'success'
        })
      })
    }
  }

  @action
  notify({ message, type }: { message: string; type: NotificationType }) {
    this.message = message
    this.type = type
  }

  @action
  setNotificationCount(count: number) {
    if (count < 0) {
      count = 0
    }

    this.notificationCount = count
  }
}

let notificationStore

export function notificationStoreFactory() {
  if (!process.browser) {
    notificationStore = {}
  }

  if (process.browser && !notificationStore) {
    // @ts-ignore
    window.notificationStore = notificationStore = new NotificationStore()
  }

  return notificationStore
}

export default notificationStore

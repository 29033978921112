import {
  css,
  Dialog,
  Drawer,
  styled,
  type DialogProps,
  type DrawerProps
} from '@mui/material'

import { type ColorMode } from '@lib/types'

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: propName => propName !== 'mode'
})<
  DialogProps & {
    mode?: ColorMode
  }
>`
  & .MuiDialog-container {
    & .MuiPaper-root {
      min-width: 450px;
      height: fit-content;
      padding: 24px;
      border-radius: 16px;

      ${({ mode = 'light' }) => {
        if (mode === 'light') {
          return css`
            background-color: var(--Neutral1);
          `
        }

        return css`
          background-color: var(--Neutral8);
        `
      }}
    }
  }
`

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: propName => propName !== 'mode'
})<
  DrawerProps & {
    mode?: ColorMode
  }
>`
  & .MuiPaper-root {
    border-radius: 12px 12px 0 0;
    padding: 24px 16px 24px 16px;

    ${({ mode = 'light' }) => {
      if (mode === 'light') {
        return css`
          background-color: var(--Neutral1);
        `
      }

      return css`
        background-color: var(--Neutral8);
      `
    }}
  }
`

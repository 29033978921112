import { isNil } from 'lodash-es'

import { useMemo } from 'react'

import NextHead from 'next/head'

import config from '@services/config'

import { getLocalAssetFromCDN } from '@helpers'

import { IHeadProps } from './Head.types'

const Head = (props: IHeadProps) => {
  const {
    title = null,
    description = null,
    url = null,
    imageUrl = null,
    keywords = null,
    children = null
  } = props

  const _title = useMemo(() => {
    if ((title || '').toLowerCase().indexOf('elite competitions') >= 0) {
      return title
    } else {
      return title + ' | Elite Competitions'
    }
  }, [title])

  const _url = useMemo(() => {
    if (isNil(url)) {
      return config.app.url
    }

    return `${config.app.url}${url}`
  }, [url])

  const _description = useMemo(() => {
    if (isNil(description)) {
      return "As seen on BBC TWO Dragons' Den. Tons of prizes to win. Come participate and win a fortune!"
    }

    return description
  }, [description])

  const _keywords = useMemo(() => {
    if (isNil(keywords)) {
      return 'Competitions, tickets, less price, big prize, win big prizes, live draws, win a fortune, purchase competition tickets, enter and win competition, small ticket prices, trustable and reliable competitions'
    }

    return keywords
  }, [keywords])

  const _imageUrl = useMemo(() => {
    return isNil(imageUrl)
      ? getLocalAssetFromCDN('/assets/logos/og-logo.png')
      : imageUrl
  }, [imageUrl])

  return (
    <NextHead>
      <title>{_title}</title>
      <meta name="description" content={_description} />
      <link rel="canonical" href={_url} />

      <meta property="og:url" content={_url} />
      <meta name="og:title" property="og:title" content={_title} />
      <meta
        name="og:description"
        property="og:description"
        content={_description}
      />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:image" content={_imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="600" />

      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={_description} />
      <meta name="twitter:image" content={_imageUrl} />
      <meta property="twitter:image:width" content="1200" />
      <meta property="twitter:image:height" content="600" />

      <meta name="keywords" content={_keywords} />

      {children}
    </NextHead>
  )
}

export default Head

import { styled } from '@mui/material'

export const StyledSingleTestimonial = styled('div')`
  text-align: center;

  h5 {
    font-size: 18px;
  }

  span {
    display: block;
    font-weight: 300;
    font-size: 16px;
  }
`

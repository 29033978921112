import { useCallback } from 'react'

import canvasConfetti from 'canvas-confetti'

import { useMediaQuery, useTheme } from '@mui/material'

export function useConfetti() {
  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  const showVictoryConfetti = useCallback(() => {
    const end = Date.now() + 2000

    ;(function frame() {
      canvasConfetti({
        zIndex: 9999,
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: {
          x: 0
        }
      })

      canvasConfetti({
        zIndex: 9999,
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: {
          x: 1
        }
      })

      if (Date.now() < end) {
        requestAnimationFrame(frame)
      }
    })()
  }, [])

  const showLuckyDipConfetti = useCallback(async () => {
    await canvasConfetti({
      particleCount: 100,
      spread: 70,
      origin: {
        y: isXs ? 1 : 0.6
      },
      ticks: 100,
      disableForReducedMotion: true,
      zIndex: 9999
    })
  }, [isXs])

  return {
    showLuckyDipConfetti,
    showVictoryConfetti
  }
}

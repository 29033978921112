import 'react-toastify/dist/ReactToastify.min.css'

import { StyledToasterContainer } from './Toaster.styled'

const Toaster = () => {
  return (
    <StyledToasterContainer
      autoClose={3000}
      hideProgressBar
      closeButton={false}
      icon={false}
    />
  )
}

export default Toaster

import { DateTime } from 'luxon'

import { isNil, orderBy } from 'lodash-es'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO
} from '@elitecompetitions/client-api'

export const isDrawToday = (
  competition: CompetitionBaseDto | CompetitionDetailsDTO
) => {
  const { drawTime, timezone } = competition

  if (isNil(drawTime)) {
    return false
  }

  const now = DateTime.now().setZone(timezone)
  const competitionDrawDate = DateTime.fromISO(drawTime, {
    zone: timezone
  }).minus({
    minutes: 5
  }) //subtract by 5min to show timer if competition is drawn at 12AM UK time

  return competitionDrawDate.hasSame(now, 'day')
}

export const splitCompetitions = (competitions: CompetitionBaseDto[]) => {
  const all = orderBy(
    competitions,
    [({ order }) => !isFinite(order), 'order', 'drawTime'],
    ['asc', 'asc', 'asc']
  )

  const drawnToday = all.filter(competition => {
    return (
      isDrawToday(competition) &&
      !competition.isSaturdaySesh &&
      !competition.isSoldOut
    )
  })

  const drawnTodayForHomeCarousel = drawnToday.filter(
    competition => !competition.isSoldOut
  )

  const featured = all.filter(
    competition =>
      competition.isFeatured &&
      !competition.hasInstantPrizes &&
      !competition.isFree &&
      !competition.isSoldOut &&
      !isDrawToday(competition)
  )

  const free = all.filter(
    competition =>
      competition.isFree &&
      !competition.isSoldOut &&
      !competition.hasInstantPrizes &&
      !isDrawToday(competition)
  )

  const featuredAndFree = featured.slice()

  featuredAndFree.splice(
    featuredAndFree.length > 4 ? 3 : featuredAndFree.length,
    0,
    ...free
  )

  const saturdaySesh = all.filter(
    competition =>
      competition.isSaturdaySesh &&
      !competition.hasInstantPrizes &&
      !competition.isFeatured &&
      !competition.isSoldOut
  )

  const carousel = all.filter(competition => competition.showInCarousel)

  const instaWins = all.filter(
    competition =>
      competition.hasInstantPrizes &&
      !competition.isSoldOut &&
      !isDrawToday(competition)
  )
  const soldOut = all.filter(competition => competition.isSoldOut)

  const notSoldOut = all.filter(competition => !competition.isSoldOut)

  const toIgnoreInOther = [
    ...featured,
    ...saturdaySesh,
    ...free,
    ...drawnToday,
    ...instaWins,
    ...soldOut
  ].map(_ => _.id)
  const other = all.filter(
    competition => !toIgnoreInOther.includes(competition.id)
  )

  return {
    all,
    carousel: {
      overRideCarousel: carousel.length > 0,
      competitions:
        carousel.length > 0
          ? carousel
          : drawnToday.filter(_ => !_.isSoldOut).length > 0
            ? drawnToday.filter(_ => !_.isSoldOut)
            : instaWins.filter(_ => !_.isSoldOut)
    },
    free,
    instaWins,
    featuredAndFree,
    drawnToday,
    drawnTodayForHomeCarousel,
    saturdaySesh,
    featured,
    soldOut,
    notSoldOut,
    other
  }
}

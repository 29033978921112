import { styled } from '@mui/material'

export const StyledCountdownRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 300px;
`

export const StyledCountdownCol = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  font-size: 12px;
  min-width: 40px;
`

export const StyledSpan = styled('span')`
  display: flex;
  padding: 0px;
  font-size: 10px;
  margin: 0px;
`

export const StyledP = styled('p')`
  display: flex;
  padding: 0px;
  font-size: 22px;
  color: #f5a623;
  margin: 0px;
`

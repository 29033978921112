import { styled, ToggleButtonGroup } from '@mui/material'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: var(--Neutral7);
    border-radius: 12px;
    height: 40px;
    padding: 4px;
  }
`

import { SvgIcon, type SvgIconProps } from '@mui/material'

const VisaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="33"
        height="23"
        rx="2"
        fill="white"
        stroke="#D1D1D5"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3838 16.2591H8.3241L6.77955 10.1933C6.70624 9.91423 6.55058 9.66755 6.32161 9.55129C5.75018 9.25912 5.12051 9.02659 4.43359 8.90932V8.67578H7.75167C8.20962 8.67578 8.55308 9.02659 8.61032 9.43402L9.41172 13.8095L11.4705 8.67578H13.473L10.3838 16.2591ZM14.6171 16.2591H12.6719L14.2737 8.67578H16.2189L14.6171 16.2591ZM18.7371 10.7757C18.7943 10.3673 19.1378 10.1337 19.5385 10.1337C20.1682 10.0751 20.8541 10.1924 21.4265 10.4835L21.77 8.85081C21.1975 8.61727 20.5679 8.5 19.9964 8.5C18.1084 8.5 16.7346 9.55041 16.7346 11.0082C16.7346 12.1173 17.7077 12.6996 18.3946 13.0504C19.1378 13.4002 19.424 13.6338 19.3668 13.9835C19.3668 14.5082 18.7943 14.7418 18.2229 14.7418C17.536 14.7418 16.8491 14.5669 16.2204 14.2747L15.877 15.9085C16.5639 16.1996 17.307 16.3169 17.9939 16.3169C20.1109 16.3745 21.4265 15.3251 21.4265 13.75C21.4265 11.7665 18.7371 11.6502 18.7371 10.7757V10.7757ZM28.2343 16.2591L26.6897 8.67578H25.0307C24.6872 8.67578 24.3438 8.90932 24.2293 9.25912L21.3691 16.2591H23.3716L23.7713 15.1511H26.2318L26.4608 16.2591H28.2343ZM25.3155 10.7148L25.887 13.5729H24.2852L25.3155 10.7148Z"
        fill="#172B85"
      />
    </SvgIcon>
  )
}

export default VisaIcon

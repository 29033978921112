import { observable, action } from 'mobx'

export class BasketStore {
  @observable isAdjusting = false

  @observable isClearing = false

  @observable isOpen: boolean = false

  @observable isDisabled: boolean = false

  constructor() {
    this.setIsAdjusting = this.setIsAdjusting.bind(this)
    this.setIsClearing = this.setIsClearing.bind(this)
    this.setIsOpen = this.setIsOpen.bind(this)
    this.setIsDisabled = this.setIsDisabled.bind(this)
  }

  @action
  setIsAdjusting(isAdjusting: boolean) {
    this.isAdjusting = isAdjusting
  }

  @action
  setIsClearing(isClearing: boolean) {
    this.isClearing = isClearing
  }

  @action
  setIsOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }

  @action
  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled
  }
}

let basketStore: BasketStore

export function basketStoreFactory() {
  if (!process.browser) {
    basketStore = new BasketStore()
  }

  if (process.browser && !basketStore) {
    basketStore = new BasketStore()
  }

  return basketStore
}

export default basketStore

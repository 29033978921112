import { useCallback, useMemo } from 'react'

import type {
  CompetitionBaseDto,
  CompetitionDetailsDTO
} from '@elitecompetitions/client-api'

import { isDrawToday } from '@helpers/competitions/splitCompetitions'
import { numericWithComma } from '@helpers'

import config from '@services/config'

import type { BadgeVariant } from '@lib/types'

import CompetitionCardBadge from '../CompetitionCardBadge'
import CompetitionCardDiscountBadge from '../CompetitionCardDiscountBadge'

import { type ICompetitionCardBadgesProps } from './CompetitionCardBadges.types'
import { StyledBadgeStack } from './CompetitionCardBadges.styled'

const CompetitionCardBadges = (props: ICompetitionCardBadgesProps) => {
  const { competition, layoutMode, showEndingSoonBadge } = props

  const { isFree, isAppOnly } = competition

  const isEndingSoon = isDrawToday(competition)

  const origTicketPrice = useMemo(() => {
    const { ticketPrice } = competition

    return ticketPrice
  }, [competition])

  const saleTicketPrice = useMemo(() => {
    const { saleTicketPrice, ticketPrice, isOnSale = false } = competition

    if (isOnSale) {
      return saleTicketPrice
    }

    return ticketPrice
  }, [competition])

  const badgeVariant = useMemo<BadgeVariant>(() => {
    return config.events.isBlackFriday ? 'blackFriday' : 'price'
  }, [])

  const renderPriceBadge = useCallback(
    (competition: CompetitionBaseDto | CompetitionDetailsDTO) => {
      const { ticketPrice, isOnSale } = competition

      if (isOnSale) {
        return (
          <CompetitionCardDiscountBadge
            layoutMode={layoutMode}
            originalPrice={origTicketPrice}
            discountPrice={saleTicketPrice}
          />
        )
      }

      return (
        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant={badgeVariant}
          badgeContent={`£${numericWithComma(ticketPrice)}`}
        />
      )
    },
    [badgeVariant, layoutMode, origTicketPrice, saleTicketPrice]
  )

  if (isAppOnly && isFree && isEndingSoon) {
    return (
      <StyledBadgeStack
        direction="row"
        justifyContent="flex-end"
        layoutMode={layoutMode}
      >
        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="appExclusive"
          badgeContent="App exclusive"
        />

        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="freeToEnter"
          badgeContent="Free to enter"
        />
      </StyledBadgeStack>
    )
  }

  if (isFree && isEndingSoon) {
    return (
      <StyledBadgeStack
        direction="row"
        justifyContent="flex-end"
        layoutMode={layoutMode}
      >
        {showEndingSoonBadge && (
          <CompetitionCardBadge
            layoutMode={layoutMode}
            variant="endingSoon"
            badgeContent="Ending soon"
          />
        )}

        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="freeToEnter"
          badgeContent="Free to enter"
        />
      </StyledBadgeStack>
    )
  }

  if (isAppOnly && isFree) {
    return (
      <StyledBadgeStack
        direction="row"
        justifyContent="flex-end"
        layoutMode={layoutMode}
      >
        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="appExclusive"
          badgeContent="App exclusive"
        />

        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="freeToEnter"
          badgeContent="Free to enter"
        />
      </StyledBadgeStack>
    )
  }

  if (isFree) {
    return (
      <StyledBadgeStack
        direction="row"
        justifyContent="flex-end"
        layoutMode={layoutMode}
      >
        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="freeToEnter"
          badgeContent="Free to enter"
        />
      </StyledBadgeStack>
    )
  }

  if (isAppOnly) {
    return (
      <StyledBadgeStack
        direction="row"
        justifyContent="flex-end"
        layoutMode={layoutMode}
      >
        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="appExclusive"
          badgeContent="App exclusive"
        />

        {renderPriceBadge(competition)}
      </StyledBadgeStack>
    )
  }

  if (isEndingSoon && showEndingSoonBadge) {
    return (
      <StyledBadgeStack
        direction="row"
        justifyContent="flex-end"
        layoutMode={layoutMode}
      >
        <CompetitionCardBadge
          layoutMode={layoutMode}
          variant="endingSoon"
          badgeContent="Ending soon"
        />

        {renderPriceBadge(competition)}
      </StyledBadgeStack>
    )
  }

  return (
    <StyledBadgeStack
      direction="row"
      justifyContent="flex-end"
      layoutMode={layoutMode}
    >
      {renderPriceBadge(competition)}
    </StyledBadgeStack>
  )
}

export default CompetitionCardBadges

import { styled } from '@mui/material'

import { ToastContainer } from 'react-toastify'

export const StyledToasterContainer = styled(ToastContainer)`
  .Toastify__toast-body {
    padding: 8px 0;
    margin: 0;
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
    padding: 0 16px;
    margin-left: 16px;
    margin-top: 50px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    border: none;
    position: fixed;
    transform: none;
    right: inherit;

    @media (min-width: 600px) {
      margin-top: 30px;
    }

    &.Toastify__toast-theme--light,
    &.Toastify__toast-theme--dark {
      &.Toastify__toast--error {
        background-color: var(--PrimaryVariant);
        color: var(--Neutral1);
      }

      &.Toastify__toast--info {
        background-color: var(--SecondaryVariant);
        color: var(--Neutral1);
      }

      &.Toastify__toast--success {
        background-color: var(--Third);
        color: var(--Neutral9);
      }

      &.Toastify__toast--warning {
        background-color: var(--ThirdVariant);
        color: var(--SecondaryVariant);
      }
    }
  }
`

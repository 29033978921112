var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.0.1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/cdnbucketassets/1854";

import * as Sentry from '@sentry/nextjs'

import config, { AppEnvTypeEnum } from '@services/config'

if (config.sentry.isAvailable) {
  Sentry.init({
    dsn: config.sentry.dsn,
    release: config.sentry.release,
    environment: config.app.env || AppEnvTypeEnum.STAGING,
    ignoreErrors: [
      /Network Error/,

      // region Firebase

      /auth\/network-request-failed/

      // endregion
    ]
  })
}

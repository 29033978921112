import { SvgIcon, type SvgIconProps } from '@mui/material'

const CartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="Group">
        <path
          d="M8.41409 0.785938C6.06096 1.08125 4.26565 3.0125 4.26565 5.25781V5.74063L3.38909 5.75938C2.55002 5.77344 2.49377 5.77813 2.24065 5.9C1.6969 6.15313 1.31721 6.64063 1.21877 7.20313C1.13909 7.68125 0.318773 19.4328 0.346898 19.7141C0.403148 20.2859 0.881273 20.9094 1.44377 21.1344L1.71096 21.2422H9.00002H16.2891L16.5563 21.1344C17.1188 20.9094 17.5969 20.2859 17.6531 19.7141C17.6813 19.4328 16.861 7.68594 16.7813 7.20781C16.7531 7.04375 16.6875 6.82344 16.6313 6.72031C16.4766 6.40625 16.1063 6.05938 15.7828 5.90938C15.5016 5.77813 15.4641 5.77344 14.6156 5.75938L13.7344 5.74063V5.36094C13.7297 4.22656 13.2188 3.03125 12.375 2.18281C11.5688 1.37188 10.65 0.921877 9.51565 0.781252C9.06565 0.729689 8.88284 0.729689 8.41409 0.785938ZM9.82034 2.35625C11.1844 2.70313 12.2344 4.02969 12.2344 5.40781V5.75H9.00002H5.76565V5.40781C5.76565 5.21563 5.80784 4.90156 5.85471 4.70938C6.30471 2.95625 8.06721 1.91094 9.82034 2.35625ZM4.28909 9.22813L4.25627 11.2109L4.36409 11.3703C4.52346 11.6 4.69221 11.7078 4.93127 11.7359C5.20315 11.7688 5.4844 11.6281 5.62971 11.3891L5.74221 11.2109L5.75627 9.22813L5.77034 7.25H9.00002H12.2297L12.2438 9.22813L12.2578 11.2109L12.3703 11.3891C12.5156 11.6281 12.7969 11.7688 13.0688 11.7359C13.3031 11.7125 13.4766 11.6 13.6313 11.375C13.7344 11.2203 13.7344 11.2156 13.7344 9.2375V7.25H14.461C15.1594 7.25 15.1875 7.25469 15.2531 7.35313C15.3047 7.43281 15.4266 8.94688 15.75 13.5266L16.1813 19.5969L16.0781 19.6813C15.9797 19.7609 15.6563 19.7656 9.00002 19.7656C2.34377 19.7656 2.02034 19.7609 1.9219 19.6813L1.81877 19.5969L2.25002 13.5266C2.57346 8.94688 2.69534 7.43281 2.7469 7.35313C2.81252 7.25469 2.83596 7.25 3.56721 7.25H4.3219L4.28909 9.22813Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  )
}

export default CartIcon

import { type ContainerProps } from '@mui/material'

import { StyledContainer } from './Container.styled'

const Container = (props: ContainerProps) => {
  const { children, ...otherProps } = props

  return <StyledContainer {...otherProps}>{children}</StyledContainer>
}

export default Container

import { styled } from '@mui/material'

import { WifiSlashIcon } from '@lib/icons'

export const StyledWifiSlashIcon = styled(WifiSlashIcon)`
  -webkit-animation: blink-1 4s both infinite;
  animation: blink-1 4s both infinite;
  transform: scale(2);

  @-webkit-keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }

  @keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }
`

import { css, styled } from '@mui/material'

import Typography from '../Typography'

import { type ILinkProps } from './Link.types'

export const StyledTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'mode'
})<Omit<ILinkProps, 'href'>>`
  color: var(--Primary);
  cursor: pointer;
  user-select: none;

  &:disabled {
    color: var(--Neutral5);
    cursor: default;
    pointer-events: none;
  }

  ${({ mode = 'dark' }) => {
    if (mode === 'dark') {
      return css`
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: var(--Neutral1);
          }
        }

        &:focus {
          color: var(--Secondary);
        }

        &:active {
          color: var(--Secondary);
        }
      `
    }

    if (mode === 'light') {
      return css`
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: var(--SecondaryVariant);
          }
        }

        &:focus {
          color: var(--Secondary);
        }

        &:active {
          color: var(--Secondary);
        }
      `
    }
  }}
`

import { isFunction } from 'lodash-es'

import { useEffect, useRef, useState } from 'react'

import { DateTime, Duration } from 'luxon'

import { addLeadingZeros } from '@helpers'

import {
  StyledP,
  StyledSpan,
  StyledCountdownRow,
  StyledCountdownCol
} from './Countdown.styled'
import { type ICountdownProps } from './Countdown.types'

const Countdown = (props: ICountdownProps) => {
  const { date, renderCountdown } = props

  const interval = useRef<number | null>(null)

  const calculateCountdown = () => {
    const now = DateTime.now()
    const diff = date.diff(now, [
      'days',
      'hours',
      'minutes',
      'seconds',
      'milliseconds'
    ])

    const formattedDiff = diff.set({
      days: Math.floor(diff.days),
      hours: Math.floor(diff.hours),
      minutes: Math.floor(diff.minutes),
      seconds: Math.floor(diff.seconds)
    })

    if (formattedDiff.milliseconds <= 0) {
      return Duration.fromObject({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      })
    }

    return formattedDiff
  }

  const stop = () => {
    clearInterval(interval.current)
  }

  const [duration, setDuration] = useState<Duration>(calculateCountdown())

  useEffect(() => {
    interval.current = window.setInterval(() => {
      const duration = calculateCountdown()

      setDuration(duration)

      if (duration.milliseconds <= 0) {
        stop()
      }
    }, 1000)

    return () => {
      stop()
    }
  }, [])

  if (isFunction(renderCountdown)) {
    return renderCountdown(duration, addLeadingZeros)
  }

  return (
    <StyledCountdownRow>
      <StyledCountdownCol>
        <StyledP suppressHydrationWarning>
          {addLeadingZeros(duration.days)}
        </StyledP>

        <StyledSpan>{duration.days === 1 ? 'Day' : 'Days'}</StyledSpan>
      </StyledCountdownCol>

      <StyledCountdownCol className="col">
        <StyledP suppressHydrationWarning>
          {addLeadingZeros(duration.hours)}
        </StyledP>

        <StyledSpan>Hrs</StyledSpan>
      </StyledCountdownCol>

      <StyledCountdownCol className="col">
        <StyledP suppressHydrationWarning>
          {addLeadingZeros(duration.minutes)}
        </StyledP>

        <StyledSpan>Min</StyledSpan>
      </StyledCountdownCol>

      <StyledCountdownCol className="col">
        <StyledP suppressHydrationWarning>
          {addLeadingZeros(duration.seconds)}
        </StyledP>

        <StyledSpan>Sec</StyledSpan>
      </StyledCountdownCol>
    </StyledCountdownRow>
  )
}

export default Countdown

import { styled, css, Stack } from '@mui/material'

import { type LayoutMode } from '@lib/types'

export const StyledBadgeStack = styled(Stack, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<{
  layoutMode?: LayoutMode
}>`
  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        gap: 4px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        gap: 2px;

        @media (min-width: 600px) {
          gap: 4px;
        }
      `
    }
  }}
`

import { css, Skeleton, styled } from '@mui/material'

import { type ISkeletonProps } from './Skeleton.types'

export const StyledSkeleton = styled(Skeleton, {
  shouldForwardProp: propName =>
    propName !== 'borderRadius' && propName !== 'mode'
})<ISkeletonProps>`
  transform: none;

  ${({ mode = 'dark' }) => {
    if (mode === 'dark') {
      return css`
        background-color: var(--Neutral6);
      `
    }

    if (mode === 'light') {
      return css`
        background-color: var(--Neutral3);
      `
    }
  }}

  ${({ borderRadius = 8 }) => {
    return css`
      border-radius: ${borderRadius}px;
    `
  }}
`

import { useMemo } from 'react'

import { Stack } from '@mui/material'

import Typography from '../Typography'

import { type IDialogHeaderProps } from './DialogHeader.types'
import { StyledIconButton } from './DialogHeader.styled'

const DialogHeader = (props: IDialogHeaderProps) => {
  const {
    dialogTitle,
    mode = 'light',
    onClose,
    children,
    ...otherProps
  } = props

  const color = useMemo(() => {
    if (mode === 'light') {
      return 'var(--Neutral10)'
    }

    return 'var(--Neutral1)'
  }, [mode])

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      {...otherProps}
    >
      <Stack direction="row" gap="4px" alignItems="center">
        <Typography
          textAlign="left"
          fontVariant="heading-4"
          weight="semibold"
          color={color}
        >
          {dialogTitle}
        </Typography>

        {children}
      </Stack>

      <StyledIconButton
        display="flex"
        alignItems="center"
        justifyContent="center"
        aria-label="close"
        height="25px"
        width="25px"
        borderRadius="100px"
        onClick={onClose}
        mode={mode}
      >
        <i className="fa-regular fa-xmark" />
      </StyledIconButton>
    </Stack>
  )
}

export default DialogHeader

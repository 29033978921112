import { Box, css, styled } from '@mui/material'

import Odometer from '../Odometer'

export const StyledOdometer = styled(Odometer, {
  shouldForwardProp: propName => propName !== 'showLabel'
})<{
  showLabel?: boolean
}>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;

  ${({ showLabel = false }) => {
    if (showLabel) {
      return css`
        justify-content: left;
        text-align: left;
        width: 45%;
      `
    }

    return css`
      justify-content: center;
      text-align: center;
      width: 100%;
    `
  }}

  ${({
    theme: {
      breakpoints: {
        values: { xs, sm, md, lg }
      }
    }
  }) => {
    return css`
      @media (min-width: ${xs}px) {
        font-size: 16px;
        line-height: 22px;
      }

      @media (min-width: ${sm}px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media (min-width: ${md}px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media (min-width: ${lg}px) {
        font-size: 20px;
        line-height: 26px;
      }
    `
  }}
`

export const StyledJackpotCharityDataBadgeContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--SecondaryVariant);
`

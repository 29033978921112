import { useCallback, useMemo } from 'react'

import {
  Box,
  Step,
  type StepIconProps,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme
} from '@mui/material'
import type { Orientation } from '@mui/material/Stepper/Stepper'

import { Typography } from '@lib/ui'

import { InfluencerProgramRequestStatus } from '@elitecompetitions/client-api'

import {
  StyledConnector,
  StyledStepIconBadge
} from './InfluencerRequestStatusStepper.styled'
import { type IInfluencerRequestStatusStepperProps } from './InfluencerRequestStatusStepper.types'

const steps = [
  {
    name: 'Application review',
    value: InfluencerProgramRequestStatus.NEW
  },
  {
    name: 'Verification',
    value: InfluencerProgramRequestStatus.CONTACTED
  },
  {
    name: 'Start Earning',
    value: InfluencerProgramRequestStatus.APPROVED
  }
]

const StepIcon = (props: StepIconProps) => {
  const { completed, icon } = props

  return (
    <Box
      display="flex"
      height="24px"
      width="24px"
      justifyContent="center"
      alignItems="center"
    >
      {completed && (
        <Typography
          fontVariant="heading-3"
          weight="medium"
          color="var(--Neutral1)"
        >
          <i className="fa fa-circle-check" />
        </Typography>
      )}

      {!completed && (
        <StyledStepIconBadge
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="var(--Neutral5)"
          width="24px"
          height="24px"
          borderRadius="100px"
        >
          <Typography
            fontVariant="body-2"
            weight="medium"
            color="var(--Neutral2)"
            borderRadius="100px"
            textAlign="center"
          >
            {icon}
          </Typography>
        </StyledStepIconBadge>
      )}
    </Box>
  )
}

const InfluencerRequestStatusStepper = (
  props: IInfluencerRequestStatusStepperProps
) => {
  const { requestStatus, ...otherProps } = props

  const theme = useTheme()

  const isSm = useMediaQuery(theme.breakpoints.down('md'))

  const stepperOrientation = useMemo<Orientation>(() => {
    if (isSm) {
      return 'vertical'
    }

    return 'horizontal'
  }, [isSm])

  const isCompleted = useCallback(
    (index: number) => {
      const { status } = requestStatus

      if (
        index === 0 &&
        (status === InfluencerProgramRequestStatus.NEW ||
          status === InfluencerProgramRequestStatus.CONTACTED ||
          status === InfluencerProgramRequestStatus.APPROVED)
      ) {
        return true
      }

      if (
        index === 1 &&
        (status === InfluencerProgramRequestStatus.CONTACTED ||
          status === InfluencerProgramRequestStatus.APPROVED)
      ) {
        return true
      }

      if (index === 2 && status === InfluencerProgramRequestStatus.APPROVED) {
        return true
      }

      return false
    },
    [requestStatus]
  )

  return (
    <Box {...otherProps}>
      <Stepper orientation={stepperOrientation} connector={<StyledConnector />}>
        {steps.map((label, index) => (
          <Step key={`influencer-request-step-${index}`}>
            <StepLabel
              StepIconComponent={StepIcon}
              StepIconProps={{
                completed: isCompleted(index),
                icon: index + 1
              }}
            >
              <Typography
                color="var(--Neutral1)"
                fontVariant="body-1"
                weight="regular"
              >
                {label.name}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default InfluencerRequestStatusStepper

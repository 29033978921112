import { type JSX, useEffect } from 'react'

import config from '@services/config'

let risk: RiskInstance | null = null

export type RiskFC = {
  (): JSX.Element | null
  getDeviceSessionId: () => Promise<string | undefined>
}

export const RiskJs: RiskFC = function RiskJs() {
  useEffect(() => {
    if (!config.cko.riskJsActive) {
      return
    }

    const script = document.createElement('script')

    script.src = config.cko.riskJsUrl
    script.async = true

    document.head.appendChild(script)

    script.onload = () => {
      risk = window.Risk?.init(config.cko.publicKey)
    }
  }, [])

  return null
}

RiskJs.getDeviceSessionId = async () => {
  if (!risk) {
    return undefined
  }

  return risk.publishRiskData()
}

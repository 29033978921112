import { Card, styled } from '@mui/material'

export const StyledCard = styled(Card)`
  padding: 24px;
  border-radius: 8px;
  background: var(--Neutral8);
  box-shadow: 0px 2px 4px 0px rgba(7, 4, 146, 0.1),
    0px 24px 60px 0px rgba(6, 47, 125, 0.05),
    0px 12px 24px 0px rgba(27, 59, 119, 0.05);
`

import { useMemo, useState } from 'react'

import sanitize from '@utils/sanitize'

import { type IFAQItemProps } from './FAQItem.types'

const FAQItem = (props: IFAQItemProps) => {
  const { groupId, question, answer, idx } = props

  const [isOpen, setOpen] = useState(false)

  const sanitizedQuestion = useMemo(() => {
    return sanitize(question)
  }, [question])

  const sanitizedAnswer = useMemo(() => {
    return sanitize(answer)
  }, [answer])

  return (
    <div className="card mb-3">
      <div className="card-header px-3" id={`card-${groupId}-${idx}`}>
        <h5 className="mb-0 website-contents">
          <button
            className={`btn btn-link ${!isOpen ? 'collapsed' : ''}`}
            data-toggle="collapse"
            data-target={`#question-${groupId}-${idx}`}
            aria-expanded={isOpen}
            aria-controls={`question-${groupId}-${idx}`}
            onClick={() => {
              setOpen(!isOpen)
            }}
            dangerouslySetInnerHTML={{
              __html: sanitizedQuestion
            }}
          />
        </h5>

        <i
          className={`fa ptr ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`}
          aria-hidden="true"
          onClick={() => {
            setOpen(!isOpen)
          }}
        />
      </div>

      <div
        id={`question-${groupId}-${idx}`}
        className={`website-contents ${isOpen ? 'collapse show' : 'collapse'}`}
        aria-labelledby={`card-${groupId}-${idx}`}
        data-parent={`#card-wrapper-${groupId}`}
      >
        <div
          className="card-body"
          dangerouslySetInnerHTML={{
            __html: sanitizedAnswer
          }}
        />
      </div>
    </div>
  )
}

export default FAQItem

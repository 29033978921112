import { SvgIcon, type SvgIconProps } from '@mui/material'

const PayPalIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58003 20.7451H1.93547C1.7545 20.7451 1.60059 20.8766 1.57237 21.0552L0.502774 27.8367C0.481515 27.9705 0.585145 28.0911 0.720878 28.0911H1.98342C2.16439 28.0911 2.3183 27.9596 2.34652 27.7807L2.635 25.9516C2.66283 25.7725 2.81712 25.6412 2.99771 25.6412H3.83489C5.57696 25.6412 6.58229 24.7981 6.84499 23.1276C6.96327 22.3968 6.85 21.8226 6.50767 21.4203C6.13188 20.9788 5.46511 20.7451 4.58003 20.7451ZM4.88514 23.2219C4.74055 24.1709 4.01549 24.1709 3.31442 24.1709H2.91534L3.19531 22.3986C3.21194 22.2916 3.30474 22.2127 3.41302 22.2127H3.59592C4.07348 22.2127 4.52402 22.2127 4.75684 22.4849C4.89552 22.6473 4.93811 22.8886 4.88514 23.2219ZM12.4853 23.1915H11.2189C11.1109 23.1915 11.0177 23.2704 11.0011 23.3776L10.9451 23.7318L10.8565 23.6035C10.5823 23.2055 9.97105 23.0725 9.36083 23.0725C7.96127 23.0725 6.76607 24.1324 6.53326 25.6193C6.41229 26.3609 6.58426 27.0701 7.00497 27.5647C7.39096 28.0194 7.9432 28.2089 8.60012 28.2089C9.72767 28.2089 10.3531 27.4838 10.3531 27.4838L10.2966 27.8358C10.2753 27.9704 10.3789 28.091 10.5138 28.091H11.6546C11.8361 28.091 11.9891 27.9594 12.0177 27.7805L12.7022 23.446C12.7238 23.3127 12.6206 23.1915 12.4853 23.1915ZM10.7199 25.6564C10.5977 26.3799 10.0235 26.8655 9.29122 26.8655C8.92348 26.8655 8.62947 26.7476 8.44085 26.5241C8.25367 26.3022 8.18245 25.9862 8.24204 25.6344C8.35621 24.917 8.94012 24.4155 9.66129 24.4155C10.0208 24.4155 10.3132 24.5349 10.5057 24.7604C10.6987 24.9882 10.7752 25.306 10.7199 25.6564ZM17.9574 23.1913H19.23C19.4083 23.1913 19.5122 23.3912 19.411 23.5374L15.1783 29.6471C15.1097 29.7461 14.9968 29.8049 14.8762 29.8049H13.6051C13.4262 29.8049 13.3217 29.6034 13.4253 29.4569L14.7433 27.5965L13.3415 23.4829C13.2931 23.3399 13.3986 23.1913 13.5507 23.1913H14.8012C14.9637 23.1913 15.1071 23.298 15.1539 23.4535L15.8978 25.938L17.6531 23.3526C17.7219 23.2517 17.836 23.1913 17.9574 23.1913Z"
        fill="#253B80"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7554 27.837L32.8407 20.9316C32.8574 20.8244 32.9502 20.7455 33.0582 20.7451H34.28C34.4149 20.7451 34.5186 20.8661 34.4973 20.9999L33.427 27.781C33.399 27.96 33.2452 28.0915 33.0639 28.0915H31.9727C31.8377 28.0915 31.7341 27.9709 31.7554 27.837ZM23.4434 20.7455H20.7984C20.6178 20.7455 20.4639 20.877 20.4356 21.0556L19.3661 27.837C19.3448 27.9709 19.4484 28.0915 19.5835 28.0915H20.9406C21.0668 28.0915 21.1747 27.9995 21.1944 27.8743L21.4979 25.952C21.5258 25.7729 21.6801 25.6415 21.8606 25.6415H22.6974C24.4398 25.6415 25.445 24.7985 25.7078 23.128C25.8265 22.3972 25.7125 21.8229 25.3703 21.4206C24.9947 20.9792 24.3285 20.7455 23.4434 20.7455ZM23.7485 23.2223C23.6043 24.1713 22.8792 24.1713 22.1777 24.1713H21.779L22.0594 22.399C22.0761 22.292 22.168 22.213 22.2767 22.213H22.4596C22.9368 22.213 23.3878 22.213 23.6204 22.4852C23.7593 22.6477 23.8015 22.8889 23.7485 23.2223ZM31.3479 23.1919H30.0824C29.9736 23.1919 29.8813 23.2708 29.865 23.378L29.809 23.7321L29.72 23.6038C29.4459 23.2058 28.835 23.0729 28.2247 23.0729C26.8252 23.0729 25.6303 24.1328 25.3975 25.6197C25.2769 26.3612 25.4482 27.0704 25.8689 27.565C26.2556 28.0197 26.8071 28.2093 27.464 28.2093C28.5916 28.2093 29.2168 27.4841 29.2168 27.4841L29.1605 27.8361C29.1392 27.9707 29.2428 28.0913 29.3786 28.0913H30.5189C30.6994 28.0913 30.8533 27.9598 30.8816 27.7808L31.5664 23.4464C31.5874 23.313 31.4838 23.1919 31.3479 23.1919ZM29.5828 25.6567C29.4613 26.3802 28.8863 26.8659 28.1539 26.8659C27.7869 26.8659 27.4923 26.748 27.3035 26.5244C27.1163 26.3026 27.046 25.9865 27.1049 25.6347C27.2196 24.9173 27.8028 24.4159 28.5239 24.4159C28.8836 24.4159 29.1759 24.5353 29.3686 24.7607C29.5622 24.9885 29.6388 25.3063 29.5828 25.6567Z"
        fill="#179BD7"
      />

      <path
        d="M14.367 18.3181L14.6922 16.2531L13.968 16.2363H10.5098L12.913 0.997656C12.9204 0.951648 12.9447 0.908754 12.9802 0.878297C13.0156 0.84784 13.061 0.831055 13.1083 0.831055H18.9393C20.8752 0.831055 22.211 1.23389 22.9086 2.02897C23.2355 2.40197 23.4438 2.79174 23.5446 3.22068C23.6502 3.67077 23.6521 4.2085 23.5489 4.86435L23.5414 4.91221V5.33244L23.8685 5.51771C24.1439 5.66378 24.3625 5.83101 24.5304 6.02248C24.8103 6.34139 24.9912 6.74671 25.0676 7.22724C25.1465 7.72146 25.1204 8.30954 24.9912 8.97534C24.842 9.74122 24.6007 10.4083 24.275 10.9541C23.9753 11.457 23.5936 11.874 23.1405 12.1974C22.7078 12.5045 22.1937 12.7376 21.6125 12.8869C21.0491 13.0334 20.4071 13.1075 19.7027 13.1075H19.2489C18.9245 13.1075 18.6092 13.2244 18.3619 13.4339C18.1138 13.6478 17.9496 13.9398 17.8993 14.2594L17.8651 14.4453L17.2907 18.085L17.2645 18.2186C17.2577 18.2611 17.2459 18.2822 17.2286 18.2965C17.213 18.3094 17.1906 18.3181 17.1688 18.3181H14.367Z"
        fill="#253B80"
      />

      <path
        d="M24.1781 4.96191C24.1608 5.07318 24.1409 5.18694 24.1186 5.30381C23.3494 9.25194 20.7187 10.6159 17.3586 10.6159H15.6478C15.237 10.6159 14.8907 10.9142 14.8266 11.3196L13.9507 16.8747L13.7027 18.4493C13.661 18.7154 13.8662 18.9554 14.1346 18.9554H17.1691C17.5284 18.9554 17.8335 18.6943 17.8901 18.3399L17.9199 18.1857L18.4913 14.5602L18.528 14.3614C18.5838 14.0058 18.8897 13.7447 19.249 13.7447H19.7028C22.6426 13.7447 24.944 12.5511 25.6167 9.09715C25.8977 7.65429 25.7522 6.44953 25.0086 5.60221C24.7837 5.34671 24.5045 5.13472 24.1781 4.96191Z"
        fill="#179BD7"
      />

      <path
        d="M23.3733 4.64125C23.2559 4.60705 23.1346 4.57596 23.0102 4.54799C22.8853 4.52065 22.7572 4.4964 22.6255 4.47527C22.1641 4.40067 21.6588 4.36523 21.1173 4.36523H16.5469C16.4344 4.36523 16.3276 4.39072 16.2318 4.43672C16.021 4.53805 15.8644 4.73761 15.8265 4.98191L14.8541 11.14L14.8262 11.3197C14.8902 10.9143 15.2365 10.616 15.6474 10.616H17.3581C20.7182 10.616 23.3491 9.25143 24.1181 5.30393C24.141 5.18706 24.1603 5.0733 24.1777 4.96203C23.9832 4.85883 23.7724 4.77055 23.5455 4.69532C23.4896 4.67668 23.4318 4.65866 23.3733 4.64125Z"
        fill="#222D65"
      />

      <path
        d="M15.8261 4.98142C15.864 4.73711 16.0208 4.53756 16.2314 4.43685C16.3279 4.39084 16.4342 4.36536 16.5466 4.36536H21.1171C21.6586 4.36536 22.1639 4.40079 22.6251 4.47539C22.757 4.49653 22.8851 4.52077 23.01 4.54812C23.1344 4.5761 23.2555 4.60719 23.3731 4.64137C23.4314 4.65878 23.4892 4.6768 23.5458 4.69484C23.7727 4.77005 23.9835 4.85895 24.178 4.96153C24.4069 3.5025 24.1762 2.5091 23.3874 1.60956C22.5177 0.619278 20.948 0.195312 18.9395 0.195312H13.1082C12.6979 0.195312 12.3481 0.493692 12.2845 0.899637L9.85584 16.2949C9.80788 16.5995 10.0428 16.8743 10.3499 16.8743H13.95L14.8539 11.1395L15.8261 4.98142Z"
        fill="#253B80"
      />
    </SvgIcon>
  )
}

export default PayPalIcon

import { SvgIcon, type SvgIconProps } from '@mui/material'

const MastercardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="34"
        height="23"
        rx="2"
        fill="white"
        stroke="#D1D1D5"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8276 17.5456C16.4921 18.6501 14.7597 19.3169 12.8666 19.3169C8.64282 19.3169 5.21875 15.9974 5.21875 11.9026C5.21875 7.80777 8.64282 4.48828 12.8666 4.48828C14.7597 4.48828 16.4921 5.15506 17.8276 6.25959C19.1631 5.15506 20.8955 4.48828 22.7885 4.48828C27.0123 4.48828 30.4364 7.80777 30.4364 11.9026C30.4364 15.9974 27.0123 19.3169 22.7885 19.3169C20.8955 19.3169 19.1631 18.6501 17.8276 17.5456Z"
        fill="#E1010B"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8271 17.5456C19.4715 16.1857 20.5141 14.1622 20.5141 11.9026C20.5141 9.643 19.4715 7.6195 17.8271 6.25958C19.1626 5.15506 20.895 4.48828 22.7881 4.48828C27.0119 4.48828 30.436 7.80777 30.436 11.9026C30.436 15.9974 27.0119 19.3169 22.7881 19.3169C20.895 19.3169 19.1626 18.6501 17.8271 17.5456Z"
        fill="#F9A000"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8276 17.5457C19.4719 16.1858 20.5145 14.1623 20.5145 11.9028C20.5145 9.64318 19.4719 7.61968 17.8276 6.25977C16.1833 7.61968 15.1406 9.64318 15.1406 11.9028C15.1406 14.1623 16.1833 16.1858 17.8276 17.5457Z"
        fill="#FF5E00"
      />
    </SvgIcon>
  )
}

export default MastercardIcon

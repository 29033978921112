import { isNil } from 'lodash-es'

import { HttpStatusCode } from 'axios'

import { observable, action } from 'mobx'

import { DateTime } from 'luxon'

import {
  child,
  get,
  getDatabase,
  onChildChanged,
  ref,
  set
} from 'firebase/database'

import * as Sentry from '@sentry/nextjs'

import {
  type UserReservedTicketsForCompetitionDTO,
  type CalculateCartPriceResultDTO,
  TicketsService,
  ERROR_CODES
} from '@elitecompetitions/client-api'

import { getServerError } from '@utils/getServerError'
import { getFirebaseApp } from '@utils/Firebase'

import { authStoreFactory } from './Auth'

const registerUsersReservedTicketsListener = async (
  userId: number,
  changeTicketHandler: () => void
) => {
  const UserReservedTicketsRef = child(
    ref(getDatabase(getFirebaseApp())),
    `UsersReservedTickets/${userId}`
  )
  const snapshot = await get(UserReservedTicketsRef)

  if (!snapshot.exists()) {
    await set(UserReservedTicketsRef, { id: userId })
  }

  changeTicketHandler()

  const ticketsChangedUnsubscribe = onChildChanged(
    UserReservedTicketsRef,
    changeTicketHandler
  )

  return () => {
    ticketsChangedUnsubscribe()
  }
}

export class CartStore {
  timerTickId: number | undefined

  cartClearTimerStepInMS = 1000

  private unsubscribeReservedTicketsListener: (() => void) | undefined =
    undefined

  @observable totalTicketsCount: number = 0

  @observable totalTicketsPrice: number = 0

  @observable cartPrice: CalculateCartPriceResultDTO | null = null

  @observable cartTicketsOverview: UserReservedTicketsForCompetitionDTO[] = []

  // region RoundUp

  @observable roundUpTicketsCount: number = 0

  // endregion

  @observable timeRemainingInSec: number | null = null

  @observable isRedeemWallet = false

  @observable appliedCoupon: string | null = null

  @observable couponErrorCode: ERROR_CODES | null = null

  @observable couponErrorMessage: string | null = null

  @observable isSyncingCart = false

  @observable isSyncingCartPrice = false

  constructor() {
    this.setIsRedeemWallet = this.setIsRedeemWallet.bind(this)
    this.setAppliedCoupon = this.setAppliedCoupon.bind(this)
    this.setIsSyncingCart = this.setIsSyncingCart.bind(this)
    this.setIsSyncingCartPrice = this.setIsSyncingCartPrice.bind(this)
    this.setAppliedCoupon = this.setAppliedCoupon.bind(this)
    this.setCouponErrorCode = this.setCouponErrorCode.bind(this)
    this.setCouponErrorMessage = this.setCouponErrorMessage.bind(this)
    this.setCartPrice = this.setCartPrice.bind(this)
    this.setTicketsData = this.setTicketsData.bind(this)
  }

  @action
  timerCountdown() {
    if (this.timeRemainingInSec > 0) {
      this.setTimer(
        this.timeRemainingInSec - this.cartClearTimerStepInMS / 1000
      )
    }
  }

  @action
  setTimer(timeInSec: number) {
    this.timeRemainingInSec = timeInSec < 0 ? 0 : timeInSec
  }

  @action
  setTicketsData({
    totalTicketsPrice = 0,
    totalTicketsCount = 0,
    roundUpTicketsCount = 0,
    cartTicketsOverview = []
  }: {
    totalTicketsPrice: number
    totalTicketsCount: number
    roundUpTicketsCount: number
    cartTicketsOverview: UserReservedTicketsForCompetitionDTO[]
  }) {
    this.totalTicketsPrice = totalTicketsPrice
    this.totalTicketsCount = totalTicketsCount
    this.roundUpTicketsCount = roundUpTicketsCount
    this.cartTicketsOverview = cartTicketsOverview
  }

  @action
  clearRoundUpTicketsCount() {
    this.roundUpTicketsCount = 0
  }

  @action
  clearCartTicketsOverview() {
    this.cartTicketsOverview = []
  }

  @action
  clearCart() {
    this.setTicketsData({
      totalTicketsCount: 0,
      totalTicketsPrice: 0,
      roundUpTicketsCount: 0,
      cartTicketsOverview: []
    })
  }

  @action
  setIsSyncingCartPrice(isSyncingCartPrice: boolean) {
    this.isSyncingCartPrice = isSyncingCartPrice
  }

  @action
  setIsSyncingCart(isSyncingCart: boolean) {
    this.isSyncingCart = isSyncingCart
  }

  @action
  setIsRedeemWallet(isRedeemWallet: boolean) {
    this.isRedeemWallet = isRedeemWallet
  }

  @action
  setAppliedCoupon(appliedCoupon: string | null) {
    this.appliedCoupon = appliedCoupon
  }

  @action
  setCouponErrorCode(couponErrorCode: ERROR_CODES | null) {
    this.couponErrorCode = couponErrorCode
  }

  @action
  setCouponErrorMessage(couponErrorMessage: string | null) {
    this.couponErrorMessage = couponErrorMessage
  }

  @action
  setCartPrice(cartPrice: CalculateCartPriceResultDTO | null) {
    this.cartPrice = cartPrice
  }

  async attachUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener =
      await registerUsersReservedTicketsListener(
        authStoreFactory().profile.id,
        async () => {
          try {
            await this.syncAll()
          } catch (error) {
            Sentry.captureException(error)
          }
        }
      )
  }

  async removeUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener?.()
  }

  async syncAll() {
    this.setIsSyncingCart(true)

    const cartTicketsOverviewResponse =
      await TicketsService.getUserReservedTicketsOverview()

    const { data: cartTicketsOverview = [], extra } =
      cartTicketsOverviewResponse

    const {
      cartClearingTime = null,
      roundUpTicketsCount = 0,
      totalTicketsCount = 0,
      totalTicketsPrice = 0
    } = extra

    this.setTicketsData({
      roundUpTicketsCount,
      cartTicketsOverview,
      totalTicketsCount,
      totalTicketsPrice
    })

    try {
      this.setIsSyncingCartPrice(true)

      const cartPrice = await TicketsService.calculateCartPrice({
        requestBody: {
          couponCode: this.appliedCoupon,
          useWallet: this.isRedeemWallet
        }
      })

      this.setCartPrice(cartPrice)
    } catch (error) {
      const serverError = getServerError(error)

      if (serverError && serverError.statusCode === HttpStatusCode.BadRequest) {
        const error = serverError.errors[0]

        this.setCouponErrorCode(error.code)
        this.setCouponErrorMessage(error.message)
      }
    } finally {
      this.setIsSyncingCartPrice(false)
    }

    this.syncCartTimer(cartClearingTime)

    if (this.timerTickId) {
      clearInterval(this.timerTickId)
    }

    this.setIsSyncingCart(false)

    this.timerTickId = window.setInterval(() => {
      this.timerCountdown()
    }, this.cartClearTimerStepInMS)
  }

  syncCartTimer(cartClearingTime: string | null) {
    if (isNil(cartClearingTime)) {
      return
    }

    const now = DateTime.now()
    const cartClearingDateTime = DateTime.fromISO(cartClearingTime)

    const remainingTimeStampInSec = cartClearingDateTime.diff(
      now,
      'seconds'
    ).seconds

    this.setTimer(remainingTimeStampInSec)
    this.timerCountdown()
  }
}

let cartStore: CartStore

export function cartStoreFactory() {
  if (!process.browser) {
    cartStore = new CartStore()
  }
  if (process.browser && !cartStore) {
    cartStore = new CartStore()
  }

  return cartStore
}

export default cartStore

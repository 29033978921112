import { initializeApp, getApps, getApp } from 'firebase/app'

import config from '@services/config'

export const initFirebase = () => {
  if (!getApps().length) {
    initializeApp({
      apiKey: config.firebase.apiKey,
      authDomain: config.firebase.authDomain,
      databaseURL: config.firebase.databaseURL,
      projectId: config.firebase.projectId,
      storageBucket: config.firebase.storageBucket,
      messagingSenderId: config.firebase.messagingSenderId
    })
  }
}

export const getFirebaseApp = () => {
  if (!getApps().length) {
    initFirebase()
  }

  return getApp()
}

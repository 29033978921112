import { useCallback, useEffect, useRef } from 'react'

import { type ICustomImageProps } from './CustomImage.types'

const CustomImage = (props: ICustomImageProps) => {
  const {
    placeholder,
    src,
    srcSet,
    errorSrc,
    loading = 'lazy',
    width,
    height,
    style,
    className,
    alt,
    onClick,
    onLoad = () => {}
  } = props

  const options = {
    draggable: false,
    ...(src && { src }),
    ...(srcSet && { srcSet }),
    ...(height && { height }),
    ...(width && { width }),
    ...(style && { style }),
    ...(className && { className }),
    ...(placeholder && { src: placeholder, srcSet: placeholder }),
    onClick
  }

  const imgRef = useRef<HTMLImageElement | null>(null)

  const loadImage = useCallback(() => {
    if (/complete|interactive/.test(document.readyState)) {
      onLoad(imgRef.current)
      if (placeholder && imgRef.current) {
        if (srcSet) {
          imgRef.current.srcset = srcSet
        }
        if (src) {
          imgRef.current.src = src
        }
      }
    } else {
      window.addEventListener('load', loadImage)
    }
  }, [placeholder, src, srcSet, errorSrc])

  useEffect(() => {
    loadImage()

    if (imgRef.current) {
      imgRef.current.onerror = () => {
        imgRef.current.removeAttribute('srcset')
        imgRef.current.src = errorSrc
        imgRef.current.onerror = null
      }
    }

    return () => {
      window.removeEventListener('load', loadImage)

      if (imgRef.current) {
        imgRef.current.onerror = null
      }
    }
  }, [])

  return (
    <picture className="aspect-ratio-box">
      <img ref={imgRef} {...options} alt={alt} loading={loading} />
    </picture>
  )
}

export default CustomImage

import { Typography } from '@mui/material'

import Badge from '../Badge'

import { type ICompetitionCardBadgeProps } from './CompetitionCardBadge.types'

const CompetitionCardBadge = (props: ICompetitionCardBadgeProps) => {
  const { badgeContent, variant = 'price', layoutMode = 'fixed' } = props

  return (
    <Badge variant={variant} layoutMode={layoutMode}>
      {layoutMode === 'fixed' && (
        <Typography
          fontFamily="Poppins"
          fontWeight={400}
          lineHeight="20px"
          fontSize="16px"
        >
          {badgeContent}
        </Typography>
      )}

      {layoutMode === 'responsive' && (
        <Typography
          fontFamily="Poppins"
          fontWeight={400}
          lineHeight={{
            lg: '20px',
            md: '18px',
            sm: '16px',
            xs: '14px'
          }}
          fontSize={{
            lg: '16px',
            md: '14px',
            sm: '12px',
            xs: '10px'
          }}
        >
          {badgeContent}
        </Typography>
      )}
    </Badge>
  )
}

export default CompetitionCardBadge

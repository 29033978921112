export enum AdalyserEventEnum {
  TRACK_EVENT = 'trackEvent',
  TRACK_SESSION = 'trackSession',
  CREATE = 'create'
}

export enum AdalyserEventTypeEnum {
  LCE2 = 'lce2',
  LCE3 = 'lce3'
}

export const adalyser = {
  register: () => {
    window.adalyserTracker?.<
      AdalyserEventEnum,
      AdalyserEventTypeEnum,
      Record<string, never>
    >(AdalyserEventEnum.TRACK_EVENT, AdalyserEventTypeEnum.LCE2, {}, true)
  },
  purchase: () => {
    window.adalyserTracker?.<
      AdalyserEventEnum,
      AdalyserEventTypeEnum,
      { value: number }
    >(AdalyserEventEnum.TRACK_EVENT, AdalyserEventTypeEnum.LCE3, {
      value: 0.0
    })
  }
}

import { isArray, isFunction, upperFirst } from 'lodash-es'

import { HttpStatusCode } from 'axios'

import { ERROR_CODES, type ErrorType } from '@elitecompetitions/client-api'

import * as Sentry from '@sentry/nextjs'

export interface IServerError {
  statusCode: number
  message: string
  timestamp: string
  errors: ErrorType[]
}

export const isServerError = (error: any): error is IServerError => {
  return error?.statusCode && error?.message && Array.isArray(error?.errors)
}

export const getServerError = (error: any): IServerError | null => {
  if (error && error.body) {
    const e = error.body

    if (isServerError(e)) {
      return e
    }
  }

  return null
}

export const getServerErrorMessage = <T = any>({
  error,
  defaultMessage,
  onValidationError = null
}: {
  error: any
  defaultMessage: string
  onValidationError?:
    | (({ field, message }: { field: T; message: string }) => void)
    | null
}) => {
  let message = defaultMessage

  const serverError = getServerError(error)

  if (serverError) {
    if (
      serverError.statusCode !== HttpStatusCode.Conflict &&
      serverError.statusCode !== HttpStatusCode.BadRequest
    ) {
      Sentry.captureException(error)
    }

    if (
      serverError.statusCode === HttpStatusCode.Conflict ||
      serverError.statusCode === HttpStatusCode.BadRequest
    ) {
      message = serverError.message

      if (isArray(serverError.errors)) {
        serverError.errors.map(error => {
          if (error.code === ERROR_CODES.VALIDATION_ERROR) {
            message = error.message

            if (isFunction(onValidationError)) {
              onValidationError({
                field: error.field as T,
                message: error.message
              })
            }
          }
        })
      }
    }
  }

  return upperFirst(message)
}

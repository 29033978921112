import { Box, css, StepConnector, styled } from '@mui/material'

export const StyledConnector = styled(StepConnector)`
  & .MuiStepConnector-line {
    border-top-width: 1px;
    border-radius: 1px;

    ${({
      theme: {
        breakpoints: {
          values: { xs, sm, md, lg }
        }
      }
    }) => {
      return css`
        @media (min-width: ${xs}px) {
          border-color: var(--Neutral4);
        }

        @media (min-width: ${sm}px) {
          border-color: var(--Neutral4);
        }

        @media (min-width: ${md}px) {
          border-color: var(--Neutral5);
        }

        @media (min-width: ${lg}px) {
          border-color: var(--Neutral5);
        }
      `
    }}
  }
`

export const StyledStepIconBadge = styled(Box)`
  border-radius: 100px;
  background: rgba(98, 98, 98, 0.7);
`

export const flutterChannelName = 'ApplicationChannel'

export enum FlutterChannelMessageActionEnum {
  LOGIN = 'login',
  LOGOUT = 'logout',
  APP_REVIEW_REQUEST = 'app-review-request',
  GOOGLE_PAY_REQUEST = 'google-pay-request'
}

export interface IFlutterChannelMessage<T = any> {
  action: FlutterChannelMessageActionEnum
  data?: T
}

export function sendFlutterChannelMessage<T = any>(
  data: IFlutterChannelMessage<T>
) {
  window[flutterChannelName]?.postMessage(JSON.stringify(data))
}

import { css, styled, TextField } from '@mui/material'

import { type ITextFieldProps } from './TextField.types'

export const StyledTextField = styled(TextField, {
  shouldForwardProp: propName =>
    propName !== 'mode' &&
    propName !== 'isError' &&
    propName !== 'isSuccess' &&
    propName !== 'isDateSelected' &&
    propName !== 'isDatePickerTextField'
})<ITextFieldProps>`
  & .MuiInputBase-root {
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding: 0;

    & .Mui-disabled {
      -webkit-text-fill-color: var(--Neutral4);
      cursor: default;
      pointer-events: none;
    }

    svg {
      color: var(--Neutral4);
    }

    fieldset {
      border: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    input,
    textarea {
      padding: 10px 16px;
      border-radius: 8px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;

      ${({ isDatePickerTextField = false }) => {
        if (isDatePickerTextField) {
          return css`
            cursor: pointer;

            &::selection {
              background-color: transparent;
            }
          `
        }
      }}

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        transition:
          background-color 0s 600000s,
          color 0s 600000s !important;
      }
    }

    ${({ multiline = false }) => {
      if (!multiline) {
        return css`
          max-height: 40px;
        `
      }
    }}

    ${({ isDatePickerTextField = false }) => {
      if (isDatePickerTextField) {
        return css`
          cursor: pointer;
          padding-right: 14px;
        `
      }
    }}

    ${({
      mode = 'light',
      isError = false,
      isSuccess = false,
      isDateSelected = false
    }) => {
      if (isSuccess) {
        return css`
          color: var(--Neutral1);
          background-color: rgba(66, 222, 181, 0.2);

          &:focus {
            border-color: transparent;
          }
        `
      }

      if (isError) {
        if (mode === 'light') {
          return css`
            color: var(--PrimaryVariant);
            background-color: rgba(192, 1, 0, 0.2);
            border-color: var(--Primary);

            &:focus {
              border-color: var(--Primary);
            }
          `
        }

        if (mode === 'dark') {
          return css`
            color: var(--Neutral4);
            background-color: rgba(192, 1, 0, 0.2);
            border-color: var(--Primary);

            &:focus {
              border-color: var(--Primary);
            }
          `
        }
      }

      if (mode === 'dark') {
        return css`
          color: ${isDateSelected ? 'var(--Neutral1)' : '#7b7b7c'};
          background-color: var(--Neutral7);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: var(--Neutral6);
            }
          }

          &:focus {
            background-color: var(--Neutral8);
            border-color: var(--Neutral5);

            svg {
              color: var(--Neutral5);
            }
          }
        `
      }

      if (mode === 'light') {
        return css`
          color: var(--Neutral7);
          background-color: var(--Neutral2);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: rgba(56, 114, 255, 0.2);
            }
          }

          &:focus {
            border-color: var(--Secondary);
          }
        `
      }
    }}
  }
`

import { styled, Checkbox } from '@mui/material'

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  max-width: 16px;
  max-height: 16px;
  color: var(--Neutral5);

  &.Mui-checked {
    color: var(--Third);
  }
`

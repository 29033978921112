import { css, styled } from '@mui/material'

import { type IButtonProps } from './Button.types'

export const StyledButton = styled('button', {
  shouldForwardProp: propName =>
    propName !== 'variant' &&
    propName !== 'size' &&
    propName !== 'mode' &&
    propName !== 'width'
})<IButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  user-select: none;
  backdrop-filter: blur(50px);

  ${({ width = 'initial' }) => {
    return css`
      width: ${width};
    `
  }}

  &:disabled {
    color: var(--Neutral3) !important;
    border: 1px solid transparent !important;
    background-color: var(--Neutral6) !important;
    cursor: default;
    pointer-events: none;
  }

  ${({ variant = 'primary', mode = 'dark' }) => {
    if (mode === 'dark') {
      if (variant === 'primary') {
        return css`
          color: var(--Neutral1);
          border: 1px solid transparent;
          background-color: var(--Primary);
          backdrop-filter: blur(50px);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: var(--PrimaryVariant);
            }
          }

          &:focus {
            background-color: var(--PrimaryVariant) !important;
            box-shadow: 0px 0px 4px 0px rgba(255, 125, 125, 0.8) !important;
          }

          &:active {
            background-color: var(--Primary);
            box-shadow: 1px 1px 4px 0px rgba(181, 0, 24, 0.8);
          }
        `
      }

      if (variant === 'secondary') {
        return css`
          color: var(--Neutral1);
          border: 1px solid var(--Secondary);
          background-color: transparent;
          backdrop-filter: blur(50px);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: var(--SecondaryVariant);
            }
          }

          &:focus {
            box-shadow: 2px 2px 4px 0px var(--Secondary) !important;
            border: 1px solid var(--Secondary) !important;
            background-color: transparent !important;
          }

          &:active {
            background-color: var(--SecondaryVariant);
            border: 1px solid transparent;
          }
        `
      }
    }

    if (mode === 'light') {
      if (variant === 'primary') {
        return css`
          color: var(--Neutral1);
          border: 1px solid transparent;
          background-color: var(--Primary);
          backdrop-filter: blur(50px);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: var(--PrimaryVariant);
            }
          }

          &:focus {
            background-color: var(--PrimaryVariant) !important;
            box-shadow: 0px 0px 4px 0px rgba(255, 125, 125, 0.8) !important;
          }

          &:active {
            background-color: var(--Primary);
            box-shadow: 1px 1px 4px 0px rgba(181, 0, 24, 0.8);
          }
        `
      }

      if (variant === 'secondary') {
        return css`
          color: var(--Neutral10);
          border: 1px solid var(--Secondary);
          background-color: transparent;
          backdrop-filter: blur(50px);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: var(--Neutral1);
              background-color: var(--SecondaryVariant);
            }
          }

          &:focus {
            color: var(--Neutral10) !important;
            box-shadow: 2px 2px 4px 0px var(--Secondary) !important;
            border: 1px solid var(--Secondary) !important;
            background-color: transparent !important;
          }

          &:active {
            color: var(--Neutral1);
            background-color: var(--SecondaryVariant);
            border: 1px solid transparent;
          }
        `
      }
    }
  }}

  ${({ size = 'large' }) => {
    if (size === 'small') {
      return css`
        max-height: 32px;
        border-radius: 6px;
        padding: 6px 16px;
      `
    }

    if (size === 'medium') {
      return css`
        max-height: 36px;
        border-radius: 8px;
        padding: 8px 24px;
      `
    }

    if (size === 'large') {
      return css`
        max-height: 40px;
        border-radius: 8px;
        padding: 10px 24px;
      `
    }
  }}
`

import { useEffect, useState, useCallback } from 'react'

const DeferredComponents = props => {
  const { Component } = props

  const [canLoad, setCanLoad] = useState(false)

  const load = useCallback(() => {
    setCanLoad(true)
  }, [])

  useEffect(() => {
    if (document.readyState === 'complete') {
      load()
    } else {
      window.addEventListener('load', load)
    }

    return () => {
      window.removeEventListener('load', load)
    }
  }, [])

  if (canLoad) {
    return Component
  }

  return null
}

export default DeferredComponents

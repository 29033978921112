import { useRef, useState, useCallback, useEffect, useMemo } from 'react'

import Grid2 from '@mui/material/Unstable_Grid2'

import sanitize from '@utils/sanitize'

import {
  StyledReadMoreButton,
  StyledCard,
  StyledCloseButton,
  StyledGridItem
} from './Collapsible.styled'
import { ICollapsibleProps } from './Collapsible.types'

const Collapsible = (props: ICollapsibleProps) => {
  const { content = '', maxHeight = '76px', visibleLines = 3 } = props

  const [isOpen, setOpen] = useState(false)

  const contentRef = useRef(null)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 3
    }
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 3
    }

    setOpen(false)
  }, [])

  const sanitizedContent = useMemo(() => {
    return sanitize(content)
  }, [content])

  return (
    <Grid2 container>
      <StyledGridItem xs={12}>
        <StyledCard
          ref={contentRef}
          isOpen={isOpen}
          visibleLines={visibleLines}
          maxHeight={maxHeight}
          dangerouslySetInnerHTML={{
            __html: sanitizedContent
          }}
        />

        {isOpen ? (
          <StyledCloseButton isOpen={isOpen} onClick={handleClose}>
            close
          </StyledCloseButton>
        ) : (
          <StyledReadMoreButton isOpen={isOpen} onClick={handleOpen}>
            read more
          </StyledReadMoreButton>
        )}
      </StyledGridItem>
    </Grid2>
  )
}

export default Collapsible

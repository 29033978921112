import { Box, Container, styled } from '@mui/material'

export const StyledCardHeader = styled(Box)`
  border-radius: 16px 16px 0 0;
  background: var(--Neutral9);
  border-bottom: 1px solid var(--Neutral6);
  color: var(--Neutral1);
`

export const StyledCardBody = styled(Box)`
  border-radius: 0 0 16px 16px;
  background: var(--Neutral9);
  color: var(--Neutral1);
`

export const StyledContainer = styled(Container)`
  padding-top: 5vh;
  padding-bottom: 5vh;
`

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

import { StyledBadge } from './Badge.styled'
import { type IBadgeProps } from './Badge.types'

const Badge = (props: IBadgeProps) => {
  const {
    className,
    variant = 'price',
    layoutMode = 'fixed',
    ...otherProps
  } = props

  return (
    <StyledBadge
      layoutMode={layoutMode}
      variant={variant}
      className={className}
      {...otherProps}
    />
  )
}

export default Badge

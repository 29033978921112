import { Typography } from '@mui/material'

const PennyCompetitionsLogo = () => {
  return (
    <Typography
      component="h1"
      borderRadius="8px"
      border="2px solid var(--Neutral1)"
      whiteSpace="wrap"
      maxWidth="155px"
      padding="5px 11px 5px 11px"
    >
      <Typography
        component="span"
        fontSize="23px"
        fontFamily="Orbitron"
        fontWeight={700}
        lineHeight="28.84px"
      >
        PENNY
      </Typography>{' '}
      <Typography
        component="span"
        fontSize="15.20px"
        fontFamily="Orbitron"
        fontWeight={700}
        lineHeight="19.41px"
      >
        COMPETITIONS
      </Typography>
    </Typography>
  )
}

export default PennyCompetitionsLogo

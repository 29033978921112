import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'

import { DateTime } from 'luxon'

import { useCallback } from 'react'

import { useSwiper } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade } from 'swiper/modules'

import { Stack, Tooltip } from '@mui/material'

import { numericWithComma } from '@helpers'

import { useConfetti } from '@hooks/useConfetti'

import { InstantPrizeType } from '@elitecompetitions/client-api'

import { type IInstantPrizeCardProps } from './InstantPrizeCard.types'
import {
  StyledCard,
  StyledPrizeNameTypography,
  StyledThumbnailPicture,
  StyledWinnerTitle,
  StyledWinningTicketBadge,
  StyledWinningDateBadge,
  StyledWinningCreditRegularBadge,
  StyledMysteryButton,
  StyledBadgeHolder,
  StyledWinningMysteryBadge
} from './InstantPrizeCard.styled'

const InstantPrizeCard = (props: IInstantPrizeCardProps) => {
  const { prize, isAccountPage = false, isMysteryPrizeOpenedDefault } = props

  const isRegularInfo =
    isAccountPage || !prize.isSecretPrize || isMysteryPrizeOpenedDefault

  const renderWinnerSection = useCallback(
    (isMysteryPrizeOpenedDefault = false, onMysteryPrizeButtonHandler) => {
      return (
        <>
          {isAccountPage && (
            <Stack spacing={1} direction="column" alignItems="flex-start">
              <StyledWinnerTitle fontVariant="body-3" weight="regular">
                Winning day
              </StyledWinnerTitle>

              <StyledWinningDateBadge fontVariant="body-3" weight="medium">
                {DateTime.fromISO(prize.wonAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </StyledWinningDateBadge>
            </Stack>
          )}

          {prize.creditAlternativeAt && (
            <Stack spacing={1} direction="column" alignItems="flex-start">
              <StyledWinnerTitle fontVariant="body-3" weight="regular">
                Wallet credited
              </StyledWinnerTitle>

              <StyledWinningCreditRegularBadge
                fontVariant="body-3"
                weight="medium"
              >
                {DateTime.fromISO(prize.creditAlternativeAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </StyledWinningCreditRegularBadge>
            </Stack>
          )}

          <Stack spacing={1} direction="column" alignItems="flex-start">
            <StyledWinnerTitle fontVariant="body-3" weight="regular">
              Winning ticket
            </StyledWinnerTitle>

            <StyledWinningTicketBadge fontVariant="body-3" weight="medium">
              {prize.ticketLabel}
            </StyledWinningTicketBadge>
          </Stack>

          {prize.isSecretPrize && (
            <Stack
              flexGrow={1}
              spacing={1}
              direction="column"
              alignItems="flex-start"
            >
              <StyledBadgeHolder>
                {isRegularInfo || isMysteryPrizeOpenedDefault ? (
                  <StyledWinningMysteryBadge
                    fontVariant="body-3"
                    weight="medium"
                  >
                    Mystery Prize
                  </StyledWinningMysteryBadge>
                ) : (
                  <StyledMysteryButton
                    type="button"
                    size="large"
                    variant="secondary"
                    onClick={onMysteryPrizeButtonHandler}
                  >
                    Reveal your Prize
                  </StyledMysteryButton>
                )}
              </StyledBadgeHolder>
            </Stack>
          )}

          {prize.type === InstantPrizeType.JACKPOT && (
            <>
              <Stack spacing={1} direction="column" alignItems="flex-start">
                <StyledWinnerTitle fontVariant="body-3" weight="regular">
                  Jackpot amount
                </StyledWinnerTitle>

                <StyledWinningCreditRegularBadge
                  fontVariant="body-3"
                  weight="medium"
                >
                  {`£${numericWithComma(prize.price, 2)}`}
                </StyledWinningCreditRegularBadge>
              </Stack>

              <Stack spacing={1} direction="column" alignItems="flex-start">
                <StyledWinnerTitle fontVariant="body-3" weight="regular">
                  To charity
                </StyledWinnerTitle>

                <StyledWinningCreditRegularBadge
                  fontVariant="body-3"
                  weight="medium"
                >
                  {`£${numericWithComma(prize.jackpotCharityPrice, 2)}`}
                </StyledWinningCreditRegularBadge>
              </Stack>
            </>
          )}
        </>
      )
    },
    [prize, isAccountPage, isRegularInfo]
  )

  const RenderCard = ({ isMysteryPrizeOpenedDefault = false }) => {
    const swiper = useSwiper()
    const { showLuckyDipConfetti } = useConfetti()

    const mysteryPrizeButtonHandler = () => {
      swiper.slideNext()
      showLuckyDipConfetti()
    }

    return (
      <StyledCard
        borderRadius={{
          sm: '16px',
          xs: '8px'
        }}
        width="100%"
        height="100%"
        overflow="hidden"
      >
        <Stack
          spacing={{
            lg: 0,
            md: 1,
            sm: 1,
            xs: 1
          }}
          direction={{
            lg: 'row',
            md: 'column',
            sm: 'column',
            xs: 'column'
          }}
          width="100%"
          height="100%"
          overflow="hidden"
        >
          <Stack
            direction={
              isAccountPage
                ? 'row'
                : {
                    lg: 'row',
                    xs: 'column'
                  }
            }
            justifyContent="space-between"
            alignItems={
              isAccountPage
                ? 'center'
                : {
                    lg: 'center',
                    xs: 'flex-start'
                  }
            }
            spacing={1}
            width="100%"
            overflow="hidden"
          >
            <StyledThumbnailPicture
              cdnImagesObj={
                isRegularInfo || isMysteryPrizeOpenedDefault
                  ? prize.image.images
                  : prize.secretPrizeImage.images
              }
              alt={
                isRegularInfo || isMysteryPrizeOpenedDefault
                  ? prize.image.altText
                  : prize.secretPrizeImage.altText
              }
              loading="eager"
              preferredSize="small"
            />

            <Stack
              direction="column"
              justifyContent="flex-start"
              spacing={1}
              width="100%"
              overflow="hidden"
            >
              <Tooltip
                title={
                  isRegularInfo || isMysteryPrizeOpenedDefault
                    ? prize.name
                    : prize.secretPrizeName
                }
              >
                <StyledPrizeNameTypography
                  fontVariant="heading-4"
                  weight="semibold"
                >
                  {isRegularInfo || isMysteryPrizeOpenedDefault
                    ? prize.name
                    : prize.secretPrizeName}
                </StyledPrizeNameTypography>
              </Tooltip>

              <Stack
                direction="row"
                spacing={2}
                alignItems="flex-end"
                display={{
                  lg: 'flex',
                  md: 'none',
                  sm: 'none',
                  xs: 'none'
                }}
              >
                {renderWinnerSection(
                  isMysteryPrizeOpenedDefault,
                  mysteryPrizeButtonHandler
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="flex-end"
            gap={1}
            display={{
              lg: 'none',
              md: 'flex',
              sm: 'flex',
              xs: 'flex'
            }}
          >
            {renderWinnerSection(
              isMysteryPrizeOpenedDefault,
              mysteryPrizeButtonHandler
            )}
          </Stack>
        </Stack>
      </StyledCard>
    )
  }

  return prize.isSecretPrize ? (
    <Swiper
      slidesPerView={1}
      loop={false}
      navigation={false}
      effect="fade"
      modules={[EffectFade]}
      speed={1200}
      followFinger={false}
      allowTouchMove={false}
      autoHeight={true}
    >
      <SwiperSlide>
        <RenderCard />
      </SwiperSlide>

      <SwiperSlide>
        <RenderCard isMysteryPrizeOpenedDefault={true} />
      </SwiperSlide>
    </Swiper>
  ) : (
    <RenderCard />
  )
}

export default InstantPrizeCard

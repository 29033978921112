import { styled, ToggleButton } from '@mui/material'

export const StyledToggleButton = styled(ToggleButton)`
  color: var(--Neutral1);

  &.MuiToggleButton-root {
    background-color: var(--Neutral7);
    height: 32px;
    border-radius: 8px;
    border: none;

    &.Mui-selected {
      color: var(--Neutral1);
      border-radius: 8px;
      background-color: var(--Neutral5);
    }
  }
`

import { SvgIcon, type SvgIconProps } from '@mui/material'

const ArrowDownIcon = (props: SvgIconProps) => {
  const { fill = 'white ', ...otherProps } = props

  return (
    <SvgIcon {...otherProps}>
      <path
        d="M12 14.475L19.35 7.12499C19.6 6.87499 19.8958 6.75415 20.2375 6.76249C20.5792 6.77082 20.875 6.89999 21.125 7.14999C21.375 7.39999 21.5 7.69582 21.5 8.03749C21.5 8.37915 21.375 8.67499 21.125 8.92499L13.425 16.6C13.225 16.8 13 16.95 12.75 17.05C12.5 17.15 12.25 17.2 12 17.2C11.75 17.2 11.5 17.15 11.25 17.05C11 16.95 10.775 16.8 10.575 16.6L2.875 8.89999C2.625 8.64999 2.50417 8.35832 2.5125 8.02499C2.52083 7.69165 2.65 7.39999 2.9 7.14999C3.15 6.89999 3.44584 6.77499 3.7875 6.77499C4.12917 6.77499 4.425 6.89999 4.675 7.14999L12 14.475Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

export default ArrowDownIcon

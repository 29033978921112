import { StyledSkeleton } from './Skeleton.styled'
import { type ISkeletonProps } from './Skeleton.types'

const Skeleton = (props: ISkeletonProps) => {
  const { borderRadius = 8, mode = 'dark', ...otherProps } = props

  return (
    <StyledSkeleton mode={mode} borderRadius={borderRadius} {...otherProps} />
  )
}

export default Skeleton

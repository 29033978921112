import { SvgIcon, type SvgIconProps } from '@mui/material'

const AmexIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect x="0.677734" width="34.0645" height="24" rx="2.5" fill="#1F72CD" />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8155 8.43066L3.63965 15.8641H7.44159L7.91292 14.6789H8.99028L9.46161 15.8641H13.6465V14.9595L14.0194 15.8641H16.1841L16.557 14.9404V15.8641H25.2604L26.3187 14.7097L27.3097 15.8641L31.7799 15.8737L28.594 12.1681L31.7799 8.43066H27.379L26.3488 9.56375L25.3891 8.43066H15.9209L15.1079 10.3493L14.2758 8.43066H10.4818V9.30447L10.0597 8.43066H6.8155ZM19.8123 9.48622H24.8102L26.3388 11.2327L27.9167 9.48622H29.4453L27.1227 12.1671L29.4453 14.8171H27.8473L26.3187 13.0503L24.7328 14.8171H19.8123V9.48622ZM21.0465 11.5643V10.5906V10.5897H24.1651L25.5258 12.1469L24.1048 13.7127H21.0465V12.6496H23.7731V11.5643H21.0465ZM7.55112 9.48622H9.40436L11.5109 14.5269V9.48622H13.5411L15.1681 13.1004L16.6676 9.48622H18.6877V14.8202H17.4585L17.4485 10.6405L15.6565 14.8202H14.557L12.755 10.6405V14.8202H10.2264L9.74706 13.6244H7.15717L6.67878 14.8192H5.32398L7.55112 9.48622ZM7.5994 12.5189L8.45267 10.3887L9.30495 12.5189H7.5994Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default AmexIcon

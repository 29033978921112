import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1240,
      xl: 1440
    }
  },
  typography: {
    fontFamily: ['Inter', 'Poppins', 'Orbitron', 'sans-serif'].join(',')
  }
})

export default theme

import {
  useRef,
  useEffect,
  useCallback,
  useState,
  type DetailedHTMLProps,
  type ImgHTMLAttributes
} from 'react'

import { isIos } from '@utils'

import { type ICompetitionImageForModalProps } from './CompetitionImageForModal.types'

const CompetitionImageForModal = (props: ICompetitionImageForModalProps) => {
  const {
    id,
    fallbackUrl,
    placeholder,
    className,
    width,
    height,
    style = null,
    onClick,
    alt = '',
    loading = 'lazy',
    sources,
    errorSrc,
    onLoad = () => {}
  } = props

  const isSafariBrowser = isIos()

  const imgAttributes: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > = {
    alt,
    loading,
    draggable: false
  }

  if (id) {
    imgAttributes.id = id
  }

  if (className) {
    imgAttributes.className = className
  }

  if (width) {
    imgAttributes.width = width
  }

  if (height) {
    imgAttributes.height = height
  }

  if (onClick) {
    imgAttributes.onClick = onClick
  }

  if (style) {
    imgAttributes.style = style
  }

  const imgRef = useRef(null)

  const [canLoad, setCanLoad] = useState(false)

  const loadImage = useCallback(() => {
    if (/complete|interactive/.test(document.readyState)) {
      onLoad(imgRef.current)

      setCanLoad(true)
      if (placeholder && imgRef.current) {
        if (fallbackUrl && !isSafariBrowser) {
          imgRef.current.src = fallbackUrl
        }
      }
    } else {
      window.addEventListener('load', loadImage)
    }
  }, [placeholder, sources])

  useEffect(() => {
    loadImage()

    if (imgRef.current) {
      imgRef.current.onerror = () => {
        setCanLoad(false)

        imgRef.current.srcset = errorSrc
        imgRef.current.src = errorSrc
        imgRef.current.onerror = null
      }
    }

    return () => {
      window.removeEventListener('load', loadImage)

      if (imgRef.current) {
        imgRef.current.onerror = null
      }
    }
  }, [])

  return (
    <picture className="aspect-ratio-box">
      {canLoad && (
        <>
          {sources.webp && (
            <>
              <source
                srcSet={sources.webp.large['3x'].cdnpath}
                media="(min-width: 769px)"
                type="image/webp"
              />
            </>
          )}

          {sources.jpeg && (
            <>
              <source
                srcSet={sources.jpeg.large['3x'].cdnpath}
                media="(min-width: 769px)"
                type="image/jpeg"
              />
            </>
          )}
        </>
      )}

      <img
        ref={imgRef}
        src={isSafariBrowser ? fallbackUrl : placeholder}
        {...imgAttributes}
        alt={imgAttributes.alt}
      />
    </picture>
  )
}

export default CompetitionImageForModal

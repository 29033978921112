import { Carousel } from 'react-responsive-carousel'

import { Dialog } from '@mui/material'

import { type IImageModalCarouselProps } from './ImageModalCarousel.types'

const ImageModalCarousel = (props: IImageModalCarouselProps) => {
  const {
    isOpen = false,
    onClose,
    thumbnails = [],
    items = [],
    selectedCompetitionIndex,
    playVideo
  } = props

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="modal fade show d-block" tabIndex={-1} role="dialog">
        <button onClick={onClose} className="img-modal-close-btn">
          &times;
        </button>

        <div className="modal-dialog modal-dialog-centered competition-detail-modal-dialog">
          <div
            className="modal-content d-flex justify-content-center h-100"
            style={{
              background: 'none'
            }}
          >
            <Carousel
              autoPlay={false}
              className="competition-detail-modal h-100 w-100 d-flex flex-column"
              showArrows={true}
              showIndicators={false}
              renderThumbs={() => {
                return thumbnails
              }}
              showStatus={false}
              thumbWidth={80}
              onClickItem={(idx, item) => {
                // @ts-ignore
                if (item.key.includes('video')) {
                  playVideo()
                }
              }}
              selectedItem={selectedCompetitionIndex}
            >
              {items}
            </Carousel>
          </div>
        </div>
      </div>

      <div
        className="modal-backdrop show fade"
        style={{
          opacity: '1'
        }}
      />
    </Dialog>
  )
}

export default ImageModalCarousel

import { type ToggleButtonGroupProps } from '@mui/material'

import { StyledToggleButtonGroup } from './ToggleButtonGroup.styled'

const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  const { children, ...otherProps } = props

  return (
    <StyledToggleButtonGroup {...otherProps}>
      {children}
    </StyledToggleButtonGroup>
  )
}

export default ToggleButtonGroup

import { useEffect, useMemo } from 'react'

import { CompetitionTilesGrid } from '@lib/ui'

import { useQuery } from '@tanstack/react-query'

import {
  type CompetitionsGetCompetitionsData,
  CompetitionsService,
  CompetitionStatus
} from '@elitecompetitions/client-api'

import { sendAppsFlyerListViewChannelMessage } from '@utils/channels/appsFlyerChannel'

import { QueryClientKeysEnum } from '@enums'

import { type IMoreInstaWinCompetitions } from './MoreInstaWinCompetitions.types'

const competitionsParams: CompetitionsGetCompetitionsData = {
  filterStatus: CompetitionStatus.ACTIVE,
  filterHasInstantPrizes: true,
  filterIsInvisible: false,
  take: -1
}

const MoreInstaWinCompetitions = (props: IMoreInstaWinCompetitions) => {
  const { analytics, heading, pageType } = props

  const { data = [], isFetched = false } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_COMPETITIONS, competitionsParams],
    queryFn: () => CompetitionsService.getCompetitions(competitionsParams),
    select: ({ data }) => data
  })

  const notSoldOutInstaWinCompetitions = useMemo(() => {
    return data.filter(({ isSoldOut }) => !isSoldOut)
  }, [data])

  useEffect(() => {
    if (isFetched) {
      ;(async () => {
        await sendAppsFlyerListViewChannelMessage({
          competitions: notSoldOutInstaWinCompetitions,
          pageType
        })
      })()
    }
  }, [isFetched])

  if (notSoldOutInstaWinCompetitions.length === 0) {
    return null
  }

  return (
    <CompetitionTilesGrid
      heading={heading}
      competitions={notSoldOutInstaWinCompetitions}
      analytics={analytics}
    />
  )
}

export default MoreInstaWinCompetitions

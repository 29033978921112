import { isNil } from 'lodash-es'

import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { QueryClientKeysEnum } from '@enums'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO,
  CompetitionsService
} from '@elitecompetitions/client-api'

export function useJackpot(props: {
  competition: CompetitionBaseDto | CompetitionDetailsDTO
}) {
  const { competition } = props

  const { id, hasJackpot = false } = competition

  const { data: competitionJackpotResponse = null, isLoading = false } =
    useQuery({
      queryKey: [QueryClientKeysEnum.GET_COMPETITION_JACKPOT, id],
      queryFn: () =>
        CompetitionsService.getCompetitionJackpot({
          id
        }),
      enabled: hasJackpot,
      refetchInterval: 5000
    })

  const jackpotAmount = useMemo(() => {
    if (isNil(competitionJackpotResponse)) {
      return 0
    }

    const { jackpotAmount } = competitionJackpotResponse

    return jackpotAmount
  }, [competitionJackpotResponse])

  const charityJackpotAmount = useMemo(() => {
    if (isNil(competitionJackpotResponse)) {
      return 0
    }

    const { charityJackpotAmount } = competitionJackpotResponse

    return charityJackpotAmount
  }, [competitionJackpotResponse])

  return {
    isLoading,
    jackpotAmount,
    charityJackpotAmount
  }
}

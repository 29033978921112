import { forwardRef } from 'react'

import { StyledButton } from './Button.styled'
import { IButtonProps } from './Button.types'

const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const {
    className,
    type = 'button',
    variant = 'primary',
    size = 'large',
    mode = 'dark',
    width = 'initial',
    ...otherProps
  } = props

  return (
    <StyledButton
      mode={mode}
      type={type}
      variant={variant}
      size={size}
      width={width}
      onMouseDown={event => {
        event.preventDefault()
      }}
      className={className}
      ref={ref}
      {...otherProps}
    />
  )
})

Button.displayName = 'Button'

export default Button

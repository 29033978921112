import { styled, Box, css } from '@mui/material'

export const StyledDialog = styled('div')`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.5);
`

export const StyledBody = styled('div')`
  width: fit-content;
  height: fit-content;
  padding: 0 24px 24px 24px;
  border-radius: 8px;
  background: var(--Neutral8);
`

export const StyledLottieContainer = styled(Box)`
  max-height: 50px;

  ${({
    theme: {
      breakpoints: {
        values: { xs, sm }
      }
    }
  }) => {
    return css`
      @media (min-width: ${xs}px) {
        transform: scale(0.3);
        margin-bottom: 10px;
      }

      @media (min-width: ${sm}px) {
        margin-bottom: 30px;
      }
    `
  }}
`

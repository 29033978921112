import { useStaticRendering } from 'mobx-react'

import { AuthStore, authStoreFactory } from './Auth'
import { LuckyDipStore, luckyDipStoreFactory } from './LuckyDip'
import { BasketStore, basketStoreFactory } from './Basket'
import { NotificationStore, notificationStoreFactory } from './Notification'
import { CartStore, cartStoreFactory } from './Cart'

const isServer = typeof window === 'undefined'

useStaticRendering(isServer)

export type ComposedStore = {
  authStore: AuthStore
  luckyDipStore: LuckyDipStore
  basketStore: BasketStore
  notificationStore: NotificationStore
  cartStore: CartStore
}

let store: ComposedStore | null = null

export default function initializeStore(
  initialStore: ComposedStore | null = null
) {
  if (!isServer && store !== null) {
    return store
  }

  const _store: ComposedStore = {
    authStore: authStoreFactory(initialStore?.authStore || null),
    notificationStore: notificationStoreFactory(),
    luckyDipStore: luckyDipStoreFactory(),
    basketStore: basketStoreFactory(),
    cartStore: cartStoreFactory()
  }

  if (isServer) {
    return _store
  }

  if (store === null) {
    store = _store
  }

  return store
}

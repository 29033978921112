import { styled, css, Box } from '@mui/material'

import { type ColorMode } from '@lib/types'

export const StyledIconButton = styled(Box)<{
  mode?: ColorMode
}>`
  cursor: pointer;

  ${({ mode = 'light' }) => {
    if (mode === 'light') {
      return css`
        color: var(--Neutral10);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: var(--Neutral2);
          }
        }

        &:active {
          background-color: var(--Neutral4);
        }
      `
    }

    return css`
      color: var(--Neutral1);

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--Neutral6);
        }
      }

      &:active {
        background-color: var(--Neutral9);
      }
    `
  }}
`

import { Stack } from '@mui/material'

import { Typography } from '@lib/ui'

import { type ICompetitionCardJackpotBadgeProps } from './CompetitionCardJackpotBadge.types'
import {
  StyledOdometer,
  StyledBadge
} from './CompetitionCardJackpotBadge.styled'

const CompetitionCardJackpotBadge = (
  props: ICompetitionCardJackpotBadgeProps
) => {
  const {
    jackpotAmount,
    isLoadingJackpot = false,
    layoutMode = 'fixed'
  } = props

  return (
    <StyledBadge variant="jackpot" layoutMode={layoutMode}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap="4px"
      >
        {layoutMode === 'fixed' && (
          <Typography fontWeight={600} lineHeight="20px" fontSize="16px">
            {isLoadingJackpot ? (
              <>
                Syncing... <i className="fa-regular fa-clover fa-spin" />
              </>
            ) : (
              'JACKPOT'
            )}
          </Typography>
        )}

        {layoutMode === 'responsive' && (
          <Typography
            fontWeight={600}
            lineHeight={{
              lg: '20px',
              md: '18px',
              sm: '16px',
              xs: '14px'
            }}
            fontSize={{
              lg: '16px',
              md: '14px',
              sm: '12px',
              xs: '10px'
            }}
          >
            {isLoadingJackpot ? (
              <>
                Syncing... <i className="fa-regular fa-clover fa-spin" />
              </>
            ) : (
              'JACKPOT'
            )}
          </Typography>
        )}

        {layoutMode === 'fixed' && !isLoadingJackpot && (
          <StyledOdometer
            enableScrollSpy={true}
            scrollSpyOnce={true}
            preserveValue={true}
            layoutMode={layoutMode}
            end={jackpotAmount}
            duration={3}
            separator=","
            decimals={2}
            decimal="."
            prefix="£"
          />
        )}

        {layoutMode === 'responsive' && !isLoadingJackpot && (
          <StyledOdometer
            enableScrollSpy={true}
            scrollSpyOnce={true}
            preserveValue={true}
            layoutMode={layoutMode}
            end={jackpotAmount}
            duration={3}
            separator=","
            decimals={2}
            decimal="."
            prefix="£"
          />
        )}
      </Stack>
    </StyledBadge>
  )
}

export default CompetitionCardJackpotBadge

import { css, styled } from '@mui/material'

import { Badge, Odometer } from '@lib/ui'
import { type LayoutMode } from '@lib/types'

export const StyledOdometer = styled(Odometer, {
  shouldForwardProp: propName => propName !== 'layoutMode'
})<{
  layoutMode: LayoutMode
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;

  ${({
    theme: {
      breakpoints: {
        values: { xs, sm, md, lg }
      }
    },
    layoutMode
  }) => {
    if (layoutMode === 'responsive') {
      return css`
        @media (min-width: ${xs}px) {
          font-size: 10px;
          line-height: 14px;
        }

        @media (min-width: ${sm}px) {
          font-size: 12px;
          line-height: 16px;
        }

        @media (min-width: ${md}px) {
          font-size: 14px;
          line-height: 18px;
        }

        @media (min-width: ${lg}px) {
          font-size: 16px;
          line-height: 20px;
        }
      `
    }

    if (layoutMode === 'fixed') {
      return css`
        line-height: 20px;
        font-size: 16px;
      `
    }
  }}
`

export const StyledBadge = styled(Badge)`
  --f: 10px; /* Control the folded part */
  --r: 15px; /* Control the ribbon shape */

  padding-right: 24px !important;
  border-bottom: var(--f) solid #0005;
  border-right: var(--r);
  clip-path: polygon(
    0 0,
    0 calc(100% - var(--f)),
    var(--f) 100%,
    var(--f) calc(100% - var(--f)),
    100% calc(100% - var(--f)),
    calc(100% - var(--r)) calc(50% - var(--f) / 2),
    100% 0
  );
`

import { observable, action } from 'mobx'

export class LuckyDipStore {
  @observable value: number = 0

  constructor() {
    this.setValue = this.setValue.bind(this)
  }

  @action
  setValue(value: number) {
    this.value = value
  }
}

let luckyDipStore: LuckyDipStore

export function luckyDipStoreFactory() {
  if (!process.browser) {
    luckyDipStore = new LuckyDipStore()
  }

  if (process.browser && !luckyDipStore) {
    luckyDipStore = new LuckyDipStore()
  }

  return luckyDipStore
}

export default luckyDipStore

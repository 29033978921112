import { type IJackpotDataBadgeProps } from './JackpotDataBadge.types'
import {
  StyledJackpotDataBadgeContainer,
  StyledOdometer,
  StyledTypography
} from './JackpotDataBadge.styled'

const JackpotDataBadge = (props: IJackpotDataBadgeProps) => {
  const {
    jackpotAmount = 0,
    isLoadingJackpot = false,
    showLabel = true,
    ...otherProps
  } = props

  return (
    <StyledJackpotDataBadgeContainer
      justifyContent="center"
      borderRadius={{
        lg: '8px',
        md: '8px',
        sm: '6px',
        xs: '6px'
      }}
      padding={{
        lg: '8px',
        md: '8px',
        sm: '6px',
        xs: '6px'
      }}
      {...otherProps}
    >
      {showLabel && (
        <StyledTypography
          fontVariant="heading-3"
          weight="semibold"
          width="45%"
          textAlign="right"
          whiteSpace="nowrap"
        >
          JACKPOT
        </StyledTypography>
      )}

      {isLoadingJackpot && (
        <StyledTypography
          fontVariant="heading-3"
          weight="semibold"
          width="100%"
          textAlign="center"
        >
          Syncing... <i className="fa-regular fa-clover fa-spin" />
        </StyledTypography>
      )}

      {!isLoadingJackpot && (
        <StyledOdometer
          showLabel={showLabel}
          enableScrollSpy={true}
          scrollSpyOnce={true}
          preserveValue={true}
          end={jackpotAmount}
          duration={3}
          separator=","
          decimals={2}
          decimal="."
          prefix="£"
        />
      )}
    </StyledJackpotDataBadgeContainer>
  )
}

export default JackpotDataBadge

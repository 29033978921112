import { type ToggleButtonProps } from '@mui/material'

import { StyledToggleButton } from './ToggleButton.styled'

const ToggleButton = (props: ToggleButtonProps) => {
  const { children, ...otherProps } = props

  return <StyledToggleButton {...otherProps}>{children}</StyledToggleButton>
}

export default ToggleButton

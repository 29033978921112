import { SvgIcon, type SvgIconProps } from '@mui/material'

const HamburgerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.50977 24V21.3333H28.5739V24H4.50977ZM4.50977 17.3333V14.6667H28.5739V17.3333H4.50977ZM4.50977 10.6667V8H28.5739V10.6667H4.50977Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default HamburgerIcon

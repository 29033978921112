import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import Lottie from 'lottie-react'

import { yellowLoadingDots } from '../../lottie'

import { type ILoaderProps } from './Loader.types'
import {
  StyledDialog,
  StyledLottieContainer,
  StyledBody
} from './Loader.styled'

const Loader = (props: ILoaderProps) => {
  const { children, isOpen } = props

  const [innerOpen, setInnerOpen] = useState(false)

  useEffect(() => {
    setInnerOpen(isOpen)
  }, [isOpen])

  if (!innerOpen) {
    return null
  }

  return createPortal(
    <StyledDialog>
      <StyledBody>
        <StyledLottieContainer>
          <Lottie animationData={yellowLoadingDots} loop={true} />
        </StyledLottieContainer>

        {children}
      </StyledBody>
    </StyledDialog>,
    document.body
  )
}

export default Loader
